import { Pipe, PipeTransform } from '@angular/core';
import { Quantity } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';

@Pipe({
  name: 'quantity',
})
export class QuantityTypePipe implements PipeTransform {
  transform(quantity: Quantity | undefined): string {
    if (!quantity) {
      return '';
    }

    const unitDisplay = CodeSystems.getDisplayForQuantity(quantity);

    const unit = unitDisplay !== undefined ? unitDisplay : quantity.unit;

    return quantity.value ? `${quantity.value}${unit ? ` ${unit}` : ''}` : '';
  }
}
