import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const MANCHET_TYPE_CODELIJST: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.4.3--20171231000000',
  systems: {
    'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.15.1': {
      STD: {
        'nl-NL': 'Standaard',
        display: 'Standard',
      },
      L: {
        'nl-NL': 'Groot',
        display: 'Large',
      },
      S: {
        'nl-NL': 'Klein',
        display: 'Small',
      },
      XL: {
        'nl-NL': 'Extra groot',
        display: 'Extra large',
      },
      KIND: {
        'nl-NL': 'Kind',
        display: 'Child',
      },
      JONGKIND: {
        'nl-NL': 'Jong kind',
        display: 'Infant',
      },
      NEONAAT: {
        'nl-NL': 'Neonaat',
        display: 'Neonate',
      },
    },
  },
};
