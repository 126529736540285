import { Injectable } from '@angular/core';
import { ISelfMeasurementsModal } from '../self-measurements-modal.interface';

@Injectable({
  providedIn: 'root',
})
export class SelfMeasurementsModalService {
  private modal: ISelfMeasurementsModal | undefined;

  setModal(modal: ISelfMeasurementsModal) {
    this.modal = modal;
  }

  open(measurementType: string | undefined) {
    if (this.modal) {
      this.modal.open(measurementType);
    }
  }

  close() {
    if (this.modal) {
      this.modal.close();
    }
  }
}
