<app-modal-step>
  <app-modal-step-header>
    <app-header class="fw-bold"
                i18n-text="@@app.self-measurements.modal.title.addHeartRate"
                text="Add Heart Rate"
                icon="heart-and-pulse-rates"></app-header>
  </app-modal-step-header>
  <app-modal-step-body>
    <app-heart-and-pulse-rates-entry [form]="form"></app-heart-and-pulse-rates-entry>
  </app-modal-step-body>
  <app-modal-step-footer>
    <div class="d-flex gap-2">
      <app-button use="block"
                  type="secondary"
                  label="Cancel"
                  i18n-label="@@app.modal.button.cancel"
                  (clicked)="close()"></app-button>
      <app-button *ngrxLet="userAd$ as userAd"
                  class="flex-grow-1"
                  use="block"
                  type="primary"
                  label="Add measurement"
                  i18n-label="@@app.self-measurements.modal.button.addMeasurement"
                  (clicked)="onSubmit(userAd)"></app-button>
    </div>
  </app-modal-step-footer>
</app-modal-step>
