<div *ngIf="loadingRowData; else loadedDataTemplate">
  <div class="grid-footer py-3">
    <div class="d-flex justify-content-center">
      <ng-lottie [options]="options"
                 width="60px"
                 height="50px"></ng-lottie>
    </div>
    <div class="d-flex justify-content-center fw-semibold">
      <h3 class="text-center">{{ text }}</h3>
    </div>
  </div>
</div>

<ng-template #loadedDataTemplate>
  <div *ngIf="rowData?.length > 0"
       class="grid-footer py-3">
  </div>
  <div *ngIf="rowData?.length === 0 && enableAddMore === true"
       class="grid-footer my-3">
    <div class="button-no-rows-container">
      <app-button type="secondary"
                  [svg]="'plus'"
                  label="Add more records"
                  (clicked)="routeToLinkedSources();"
                  i18n-label="@@app.helper.button.addRecords"></app-button>
      <div class="icon-health-records">
        <svg>
          <use xlink:href="./assets/images/assets.svg#health-records"></use>
        </svg>
      </div>
    </div>
  </div>
</ng-template>