import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';
import { INoRowsOverlayParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
  standalone: true,
  template: '<h3 class="fw-bold" i18n="@@app.helper.nothingToDisplay">Nothing to display</h3>',
})
export class NoRowsOverlayComponent implements INoRowsOverlayAngularComp {
  public params!: INoRowsOverlayParams & { noRowsMessageFunc: () => string };

  agInit(params: INoRowsOverlayParams & { noRowsMessageFunc: () => string }): void {
    this.params = params;
  }
}
