import { Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-background-icon',
  styles: [
    `
      .wrapper {
        padding: 0 0 0 100px;
      }

      svg {
        transform: rotate(-15deg);
        width: 498px;
        height: 498px;
        fill-opacity: 0.3;
      }
    `,
  ],
  template: `
    <div class="wrapper">
      <svg>
        <use attr.xlink:href="./assets/images/assets.svg#{{ icon }}"></use>
      </svg>
    </div>
  `,
})
export class BackgroundIconComponent {
  @Input({ required: true }) icon: string | undefined;
}
