import { createActionGroup, props } from '@ngrx/store';

export type SortDirection = 'asc' | 'desc' | string | undefined;

export const SET_SORT_BY = createActionGroup({
  source: 'Digi.me Set Sort By',
  events: {
    'Update Sort By': props<{
      sortBy: string | undefined;
      sortDirection: SortDirection | undefined;
    }>(),
  },
});
