import { environment } from '@environments/environment';
import { addIf } from '@globals';

/**
 * Gets the menu config.
 *
 * NOTE: This isn't a constant anymore, because we use runtime translations,
 * and therefore the $localize values are undefined when using this as a constant.
 * @returns The menu config
 */
export function getMenuConfig() {
  return {
    menuItems: [
      {
        header: $localize`:@@app.Notifications.header:Activity`,
        path: `/${$localize.locale}/notifications`,
        icon: 'activity',
      },
      {
        placeholder: true,
      },
      {
        header: $localize`:@@app.menu.myDetails:My Details`,
        headerTopMenu: $localize`:@@app.menu.details:Details`,
        path: `/${$localize.locale}/my-details`,
        icon: 'my-details',
        subMenu: [
          {
            header: $localize`:@@app.menu.patientDetails:Patient Details`,
            path: `/${$localize.locale}/my-details/patient-details`,
            icon: 'patient-details',
          },
          ...addIf(environment.features.flags, {
            header: $localize`:@@app.menu.flags:Flags`,
            path: `/${$localize.locale}/my-details/flags`,
            icon: 'flags',
          }),
          ...addIf(environment.features.patientContacts, {
            header: $localize`:@@app.menu.contactPersons:Contact Persons`,
            path: `/${$localize.locale}/my-details/contact-persons`,
            icon: 'contact-persons',
          }),
          ...addIf(environment.features.healthCareProvider, {
            header: $localize`:@@app.menu.HealthcareProviders:Healthcare Providers`,
            path: `/${$localize.locale}/my-details/healthcare-providers`,
            icon: 'healthcare-providers',
          }),
          ...addIf(environment.features.healthProfessional, {
            header: $localize`:@@app.menu.HealthProfessional:Health Professional`,
            path: `/${$localize.locale}/my-details/health-professional`,
            icon: 'health-professional',
          }),
          ...addIf(environment.features.insurance, {
            header: $localize`:@@app.menu.Insurance:Insurance`,
            path: `/${$localize.locale}/my-details/insurance`,
            icon: 'insurance',
          }),
          ...addIf(environment.features.functionalOrMentalStatus, {
            header: $localize`:@@app.menu.FunctionalOrMentalStatus:Functional Or Mental status`,
            path: `/${$localize.locale}/my-details/functional-or-mental-status`,
            icon: 'mental-status',
          }),
          ...addIf(environment.features.consent, {
            header: $localize`:@@app.menu.Consent:Consent`,
            path: `/${$localize.locale}/my-details/consent`,
            icon: 'consent',
          }),
          ...addIf(environment.features.livingSituation, {
            header: $localize`:@@app.menu.LivingSituation:Living Situation`,
            path: `/${$localize.locale}/my-details/living-situation`,
            icon: 'living-situation',
          }),
          ...addIf(environment.features.lifeStyle, {
            header: $localize`:@@app.menu.lifestyle:Lifestyle`,
            path: `/${$localize.locale}/my-details/lifestyle`,
            icon: 'lifestyle',
            subMenu: [
              ...addIf(environment.features.intoxications, {
                header: $localize`:@@app.menu.intoxications:Intoxications`,
                path: `/${$localize.locale}/my-details/lifestyle/intoxications`,
                icon: 'intoxications',
                subMenu: [
                  ...addIf(environment.features.drugUse, {
                    header: $localize`:@@app.menu.intoxications.drugUse:Drug Use`,
                    path: `/${$localize.locale}/my-details/lifestyle/intoxications/drug-use`,
                    icon: 'drug-use',
                  }),
                  ...addIf(environment.features.tobaccoUse, {
                    header: $localize`:@@app.menu.intoxications.tobaccoUse:Tobacco Use`,
                    path: `/${$localize.locale}/my-details/lifestyle/intoxications/tobacco-use`,
                    icon: 'tobacco-use',
                  }),
                  ...addIf(environment.features.alcoholUse, {
                    header: $localize`:@@app.menu.intoxications.alcoholUse:Alcohol Use`,
                    path: `/${$localize.locale}/my-details/lifestyle/intoxications/alcohol-use`,
                    icon: 'alcohol-use',
                  }),
                ],
              }),
              ...addIf(environment.features.nutritionAdvice, {
                header: $localize`:@@app.menu.nutritionAdvice:Nutrition Advice`,
                path: `/${$localize.locale}/my-details/lifestyle/nutrition-advices`,
                icon: 'nutrition-advices',
              }),
            ],
          }),
        ],
      },
      {
        header: $localize`:@@app.menu.healthRecord:Health Record`,
        headerTopMenu: $localize`:@@app.menu.records:Records`,
        path: `/${$localize.locale}/my-health-record`,
        icon: 'health-record',
        subMenu: [
          ...addIf(environment.features.allergyIntolerance, {
            header: $localize`:@@app.menu.allergyIntolerances:Allergy Intolerances`,
            path: `/${$localize.locale}/my-health-record/allergy-intolerances`,
            icon: 'allergy-intolerances',
          }),
          ...addIf(environment.features.appointment, {
            header: $localize`:@@app.menu.Appointments:Appointments`,
            path: `/${$localize.locale}/my-health-record/appointments`,
            icon: 'appointments',
          }),
          {
            header: $localize`:@@app.menu.measurement:Measurements`,
            path: `/${$localize.locale}/my-health-record/measurements`,
            icon: 'measurements',
            subMenu: [
              {
                header: $localize`:@@app.menu.bloodPressure:Blood pressure`,
                path: `/${$localize.locale}/my-health-record/measurements/blood-pressure`,
                icon: 'blood-pressure',
              },
              ...addIf(environment.features.bodyWeight, {
                header: $localize`:@@app.menu.bodyWeight:Body Weight`,
                path: `/${$localize.locale}/my-health-record/measurements/body-weights`,
                icon: 'body-weights',
              }),
              ...addIf(environment.features.bodyHeight, {
                header: $localize`:@@app.menu.bodyHeight:Body Height`,
                path: `/${$localize.locale}/my-health-record/measurements/body-heights`,
                icon: 'body-heights',
              }),
              ...addIf(environment.features.bodyTemperature, {
                header: $localize`:@@app.menu.bodyTemperature:Body Temperature`,
                path: `/${$localize.locale}/my-health-record/measurements/body-temperatures`,
                icon: 'body-temperatures',
              }),
              ...addIf(environment.features.heartAndPulseRates, {
                header: $localize`:@@app.menu.heartAndPulseRates:Heart and Pulse Rates`,
                path: `/${$localize.locale}/my-health-record/measurements/heart-and-pulse-rates`,
                icon: 'heart-and-pulse-rates',
              }),
              ...addIf(environment.features.glucose, {
                header: $localize`:@@app.menu.glucose:Glucose`,
                path: `/${$localize.locale}/my-health-record/measurements/glucoses`,
                icon: 'glucoses',
              }),
              ...addIf(environment.features.o2saturations, {
                header: $localize`:@@app.menu.o2Saturation:O2 Saturation`,
                path: `/${$localize.locale}/my-health-record/measurements/o2-saturations`,
                icon: 'o2-saturations',
              }),
              ...addIf(environment.features.respiratory, {
                header: $localize`:@@app.menu.respiration:Respiration`,
                path: `/${$localize.locale}/my-health-record/measurements/respiratory`,
                icon: 'respiration',
              }),
            ],
          },
          {
            header: $localize`:@@app.menu.gp-records:GP Records`,
            path: `/${$localize.locale}/my-health-record/gp-records`,
            icon: 'gp-records',
            subMenu: [
              ...addIf(environment.features.episodeOfCare, {
                header: $localize`:@@app.menu.episodeOfCare:Episodes`,
                path: `/${$localize.locale}/my-health-record/gp-records/episodes-of-care`,
                icon: 'episodes-of-care',
              }),
              ...addIf(environment.features.journal, {
                header: $localize`:@@app.menu.journal:Journal`,
                path: `/${$localize.locale}/my-health-record/gp-records/journal`,
                icon: 'journal',
              }),
            ],
          },
          ...addIf(environment.features.diagnoses, {
            header: $localize`:@@app.menu.diagnoses:Diagnoses`,
            path: `/${$localize.locale}/my-health-record/diagnoses`,
            icon: 'diagnoses',
          }),
          ...addIf(environment.features.documents, {
            header: $localize`:@@app.menu.documents:Documents`,
            path: `/${$localize.locale}/my-health-record/documents`,
            icon: 'documents',
          }),
          ...addIf(environment.features.encounter, {
            header: $localize`:@@app.menu.encounters:Encounters`,
            path: `/${$localize.locale}/my-health-record/encounters`,
            icon: 'encounters',
          }),
          ...addIf(environment.features.vaccinations, {
            header: $localize`:@@app.menu.vaccinations:Vaccinations`,
            path: `/${$localize.locale}/my-health-record/vaccinations`,
            icon: 'vaccinations',
          }),
          ...addIf(environment.features.results, {
            header: $localize`:@@app.menu.results:Results`,
            path: `/${$localize.locale}/my-health-record/results`,
            icon: 'results',
            subMenu: [
              ...addIf(environment.features.laboratory, {
                header: $localize`:@@app.menu.laboratory:Laboratory`,
                path: `/${$localize.locale}/my-health-record/results/laboratory`,
                icon: 'laboratory',
              }),
              ...addIf(environment.features.imaging, {
                header: $localize`:@@app.menu.myMedicalRecord.results.imaging:Imaging`,
                path: `/${$localize.locale}/my-health-record/results/imaging`,
                icon: 'imaging',
              }),
            ],
          }),
          ...addIf(environment.features.medication, {
            header: $localize`:@@app.menu.medication:Medication`,
            path: `/${$localize.locale}/my-health-record/medication`,
            icon: 'medication',
          }),
          ...addIf(environment.features.medicalAids, {
            header: $localize`:@@app.menu.medicalAids:Medical Aids`,
            path: `/${$localize.locale}/my-health-record/medical-aids`,
            icon: 'medical-aids',
          }),
          ...addIf(environment.features.procedures, {
            header: $localize`:@@app.menu.Procedures:Procedures`,
            path: `/${$localize.locale}/my-health-record/procedures`,
            icon: 'procedures',
          }),
        ],
      },
      {
        placeholder: true,
      },
      ...addIf(environment.features.opt, {
        header: $localize`:@@app.promo.optInResearch.title:Opt in Research`,
        headerTopMenu: $localize`:@@app.promo.optInResearch.title:Opt in Research`,
        path: `/${$localize.locale}/opt`,
        icon: 'consent',
      }),
      ...addIf(environment.features.ips, {
        header: $localize`:@@app.menu.ips:Patient Summery (IPS)`,
        headerTopMenu: $localize`:@@app.promo.ips.title:International Patient Summary (IPS)`,
        path: `/${$localize.locale}/ips`,
        icon: 'ips',
      }),
      {
        placeholder: true,
      },
      ...addIf(environment.features.linkedSources, {
        header: $localize`:@@app.menu.linkedSources:Linked Sources`,
        headerTopMenu: $localize`:@@app.menu.sources:Sources`,
        path: `/${$localize.locale}/linked-sources`,
        icon: 'sources',
      }),
      {
        header: $localize`:@@app.settings:Settings`,
        path: `/${$localize.locale}/settings`,
        icon: 'settings',
      },
    ],
  };
}
