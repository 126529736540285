import { Injectable } from '@angular/core';
import { IModalStep } from './modal-step.interface';

@Injectable()
export class ModalStepService {
  private child: IModalStep | null = null;

  setChild(child: IModalStep) {
    this.child = child;
  }

  getChild(): IModalStep | null {
    return this.child;
  }
}
