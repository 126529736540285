export function addIf<T>(condition: boolean, ...itemToAdd: T[]): T[] {
  if (condition) {
    return [...itemToAdd];
  }
  return [];
}

export function addIfProperty(
  condition: boolean,
  key: string,
  value: string | number | boolean | undefined | null
): object {
  return condition ? { [key]: value } : {};
}
