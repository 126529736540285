import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { nameof } from '@globals';
import { Coding } from '@hl7fhir';
import { ValueSets } from '@hl7fhir/value-sets';
import {
  HOUDING_CODELIJST,
  MANCHET_TYPE_CODELIJST,
  MEETLOCATIE_CODELIJST,
  MEETMETHODE_CODELIJST,
} from '@hl7nl-fhir/value-sets';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-blood-pressure-entry',
  templateUrl: './blood-pressure-entry.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class BloodPressureEntryComponent {
  public static houdingCodelijstCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '10904000', display: 'Orthostatic body position' },
    { system: 'http://snomed.info/sct', code: '102538003', display: 'Recumbent body position' },
    { system: 'http://snomed.info/sct', code: '33586001', display: 'Sitting position' },
    { system: 'http://snomed.info/sct', code: '272587006', display: 'Position with tilt' },
    { system: 'http://snomed.info/sct', code: '34106002', display: 'Trendelenburg position' },
  ];

  public static mancheTypeCodelijstCodings: Coding[] = [
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.15.1', code: 'STD', display: 'Standard' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.15.1', code: 'L', display: 'Large' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.15.1', code: 'S', display: 'Small' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.15.1', code: 'XL', display: 'Extra large' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.15.1', code: 'KIND', display: 'Child' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.15.1', code: 'JONGKIND', display: 'Infant' },
    { system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.15.1', code: 'NEONAAT', display: 'Neonate' },
  ];

  public static meetlocatieCodelijstCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '40983000', display: 'Upper arm structure' },
    { system: 'http://snomed.info/sct', code: '368209003', display: 'Right upper arm structure' },
    { system: 'http://snomed.info/sct', code: '368208006', display: 'Left upper arm structure' },
    { system: 'http://snomed.info/sct', code: '68367000', display: 'Thigh structure' },
    { system: 'http://snomed.info/sct', code: '11207009', display: 'Structure of right thigh' },
    { system: 'http://snomed.info/sct', code: '61396006', display: 'Structure of left thigh' },
    { system: 'http://snomed.info/sct', code: '8205005', display: 'Wrist region structure' },
    { system: 'http://snomed.info/sct', code: '9736006', display: 'Structure of right wrist' },
    { system: 'http://snomed.info/sct', code: '5951000', display: 'Structure of left wrist' },
    { system: 'http://snomed.info/sct', code: '7569003', display: 'Finger structure' },
    { system: 'http://snomed.info/sct', code: '344001', display: 'Ankle region structure' },
    { system: 'http://snomed.info/sct', code: '6685009', display: 'Structure of right ankle' },
    { system: 'http://snomed.info/sct', code: '51636004', display: 'Structure of left ankle' },
  ];

  public static meetmethodeCodelijstCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '22762002', display: 'Non-invasive' },
    { system: 'http://snomed.info/sct', code: '10179008', display: 'Invasive' },
  ];

  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  readonly houdingCodelijst = HOUDING_CODELIJST;
  readonly manchetTypeCodelijst = MANCHET_TYPE_CODELIJST;
  readonly meetlocatieCodelijst = MEETLOCATIE_CODELIJST;
  readonly meetmethodeCodelijst = MEETMETHODE_CODELIJST;
  readonly translatedHoudingCodelijst: Coding[];
  readonly translatedManchetTypeCodelijst: Coding[];
  readonly translatedMeetlocatieCodelijst: Coding[];
  readonly translatedMeetmethodeCodelijst: Coding[];

  constructor() {
    this.translatedHoudingCodelijst = BloodPressureEntryComponent.houdingCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [HOUDING_CODELIJST]);
      return <Coding>{ ...coding, display: display.code };
    });
    this.translatedManchetTypeCodelijst = BloodPressureEntryComponent.mancheTypeCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [MANCHET_TYPE_CODELIJST]);
      return <Coding>{ ...coding, display: display.code };
    });
    this.translatedMeetlocatieCodelijst = BloodPressureEntryComponent.meetlocatieCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [MEETLOCATIE_CODELIJST]);
      return <Coding>{ ...coding, display: display.code };
    });
    this.translatedMeetmethodeCodelijst = BloodPressureEntryComponent.meetmethodeCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [MEETMETHODE_CODELIJST]);
      return <Coding>{ ...coding, display: display.code };
    });
  }

  get maxDay(): string {
    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOString = new Date(Date.now() - tzoffset).toISOString();
    const dateString = localISOString.substring(0, 16);

    return dateString;
  }

  get dateControl() {
    return this.form.get(nameof<BloodPressureEntryComponent>('dateControl'));
  }

  get systolicControl(): any {
    return this.form.get(nameof<BloodPressureEntryComponent>('systolicControl'));
  }

  get diastolicControl(): any {
    return this.form.get(nameof<BloodPressureEntryComponent>('diastolicControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  limitAndFormatDecimalInput(target: EventTarget | null, control: any): void {
    const input = target as HTMLInputElement;
    const maxNumber = 999;
    const minNumber = 0;
    const value = parseInt(input.value);

    if (value >= maxNumber) {
      input.value = maxNumber.toString();
      control!.setValue(input.value);
    } else if (value <= minNumber) {
      input.value = minNumber.toString();
      control!.setValue(input.value);
    }
  }
}
