import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType } from '@digi.me/models';
import { addIf, addIfProperty } from '@globals';
import { CodeSystems } from '@hl7fhir/codesystems';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { UserAD } from '../digi.me/models/ad-user.model';
import { BloodPressureEntryComponent } from './blood-pressure-entry/blood-pressure-entry.component';
import { BLOOD_PRESSURE_TEMPLATE } from './blood-pressure-entry/blood-pressure.template';
import { SelfMeasurementsMenuComponent } from './self-measurements-menu.component';
import { SelfMeasurementsModalService } from './services/self-measurements-modal.service';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-blood-pressure',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    SelfMeasurementsMenuComponent,
    ButtonComponent,
    HeaderComponent,
    SharedModule,
    BloodPressureEntryComponent,
  ],
  templateUrl: './self-measurements-modal-blood-pressure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalBloodPressureComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    systolicControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    diastolicControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    positionControl: new FormControl(''),
    cuffSizeControl: new FormControl(''),
    locationControl: new FormControl(''),
    methodControl: new FormControl(''),
    additionalCommentsControl: new FormControl(''),
  });

  readonly user$ = this.store.select(selectUser);

  private service = inject(SelfMeasurementsModalService);

  constructor(private readonly store: Store) {}

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    const systolic = this.form.value['systolicControl'];
    const diastolic = this.form.value['diastolicControl'];
    const position = this.form.value['positionControl'] ?? undefined;
    const cuffSize = this.form.value['cuffSizeControl'] ?? undefined;
    const location = this.form.value['locationControl'] ?? undefined;
    const method = this.form.value['methodControl'] ?? undefined;
    const comment = this.form.value['additionalCommentsControl'] ?? undefined;

    const observation: r3.Observation = {
      ...BLOOD_PRESSURE_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...BLOOD_PRESSURE_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...BLOOD_PRESSURE_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      component: [
        {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '8480-6',
                display: 'Systolic blood pressure',
              },
            ],
          },
          valueQuantity: {
            value: parseInt(systolic!),
            unit: 'mmHg',
            system: 'http://unitsofmeasure.org',
            code: 'mm[Hg]',
          },
        },
        {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '8462-4',
                display: 'Diastolic blood pressure',
              },
            ],
          },
          valueQuantity: {
            value: parseInt(diastolic!),
            unit: 'mmHg',
            system: 'http://unitsofmeasure.org',
            code: 'mm[Hg]',
          },
        },
        ...addIf(!!cuffSize, {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '8358-4',
                display: 'Blood pressure cuff size',
              },
            ],
          },
          valueCodeableConcept: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: cuffSize,
                display: BloodPressureEntryComponent.mancheTypeCodelijstCodings.find(
                  (coding) => coding.code === cuffSize
                )?.display,
              },
            ],
          },
        }),
        ...addIf(!!position, {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '8361-8',
                display: 'Body position with respect to gravity',
              },
            ],
          },
          valueCodeableConcept: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: position,
                display: BloodPressureEntryComponent.houdingCodelijstCodings.find((coding) => coding.code === position)
                  ?.display,
              },
            ],
          },
        }),
      ],
      bodySite: {
        coding: [
          ...addIf(!!location, {
            system: 'http://snomed.info/sct',
            code: location,
            display: BloodPressureEntryComponent.meetlocatieCodelijstCodings.find((coding) => coding.code === location)
              ?.display,
          }),
        ],
      },
      method: {
        coding: [
          ...addIf(!!method, {
            system: 'http://snomed.info/sct',
            code: method,
            display: BloodPressureEntryComponent.meetmethodeCodelijstCodings.find((coding) => coding.code === method)
              ?.display,
          }),
        ],
      },
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: 'self-measurements',
        measurementType: MeasurementType.bloodPressure,
      })
    );

    this.close();
  }

  close() {
    this.onModalClose();

    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      systolicControl: '',
      diastolicControl: '',
      positionControl: '',
      cuffSizeControl: '',
      locationControl: '',
      additionalCommentsControl: '',
    });
  }
}
