import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DigiMeStoreModule } from '@store/digi.me';
import { DigiMeLoadingComponent } from './digi-me-loading/digi-me-loading.component';

const components = [DigiMeLoadingComponent];

@NgModule({
  imports: [CommonModule, DigiMeStoreModule, RouterModule],
  exports: [...components],
  declarations: [...components],
})
export class DigiMeModule {}
