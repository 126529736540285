import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuItem } from '../menu';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  @Input() menu: MenuItem[] | undefined | null;
}
