import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType, UserAD } from '@digi.me/models';
import { addIf, addIfProperty } from '@globals';
import { CodeSystems } from '@hl7fhir/codesystems';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { HeartAndPulseRatesEntryComponent } from './heart-and-pulse-rates-entry/heart-and-pulse-rates-entry.component';
import { HEARTRATE_TEMPLATE } from './heart-and-pulse-rates-entry/heart-and-pulse-rates.template';
import { SelfMeasurementsMenuComponent } from './self-measurements-menu.component';
import { SelfMeasurementsModalService } from './services/self-measurements-modal.service';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-heartrate',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    SelfMeasurementsMenuComponent,
    ButtonComponent,
    HeaderComponent,
    SharedModule,
    HeartAndPulseRatesEntryComponent,
  ],
  templateUrl: './self-measurements-modal-heartrate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalHeartRateComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    heartRateControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    methodControl: new FormControl(''),
    interpretationControl: new FormControl(''),
    additionalCommentsControl: new FormControl(''),
  });

  readonly user$ = this.store.select(selectUser);

  private service = inject(SelfMeasurementsModalService);

  constructor(private readonly store: Store) {}

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    const heartRate = this.form.value['heartRateControl'];
    const comment = this.form.value['additionalCommentsControl'] ?? undefined;
    const method = this.form.value['methodControl'] ?? undefined;
    const interpretation = this.form.value['interpretationControl'] ?? undefined;

    const observation: r3.Observation = {
      ...HEARTRATE_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...HEARTRATE_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...HEARTRATE_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      valueQuantity: {
        ...HEARTRATE_TEMPLATE.valueQuantity,
        value: parseInt(heartRate!),
      },
      component: [],
      method: {
        coding: [
          ...addIf(!!method, {
            system: 'http://snomed.info/sct',
            code: method,
            display: HeartAndPulseRatesEntryComponent.hartslagMeetMethodeCodelistCodings.find(
              (coding) => coding.code === method
            )?.display,
          }),
        ],
      },
      interpretation: {
        coding: [
          ...addIf(!!interpretation, {
            system: 'http://snomed.info/sct',
            code: interpretation,
            display: HeartAndPulseRatesEntryComponent.hartslagRegelmatigheidCodelistCodings.find(
              (coding) => coding.code === method
            )?.display,
          }),
        ],
      },
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: 'self-measurements',
        measurementType: MeasurementType.heartRate,
      })
    );

    this.close();
  }

  close() {
    this.onModalClose();

    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      heartRateControl: '',
      additionalCommentsControl: '',
      methodControl: '',
      interpretationControl: '',
    });
  }
}
