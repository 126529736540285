export const DIGI_ME = 'digi.me';

export const APP = 'app';

export const PAGE = 'page';

export const FHIR = {
  patients: 'fhir-patients',
  coverages: 'fhir-coverages',
  organizations: 'fhir-organizations',
  healthcareServices: 'fhir-healthcare-services',
  practitioners: 'fhir-practitioners',
  practitionerRoles: 'fhir-practitioner-roles',
  observations: 'fhir-observations',
  allergyIntolerances: 'fhir-allergy-intolerances',
  conditions: 'fhir-conditions',
  immunizations: 'fhir-immunizations',
  immunizationRecommendations: 'fhir-immunization-recommendations',
  specimen: 'fhir-specimens',
  consent: 'fhir-consents',
  medicationRequest: 'fhir-medication-request',
  medicationStatements: 'fhir-medication-statements',
  medicationDispenses: 'fhir-medication-dispenses',
  devices: 'fhir-devices',
  encounters: 'fhir-encounters',
  procedures: 'fhir-procedures',
  flags: 'fhir-flags',
  imagingStudies: 'fhir-imaging-studies',
  medication: 'fhir-medication',
  composition: 'fhir-composition',
  documentReference: 'fhir-document-reference',
  documentManifest: 'fhir-document-manifest',
  nutritionOrders: 'fhir-nutrition-orders',
  episodeOfCare: 'fhir-episode-of-care',
  patientContacts: 'fhir-patient-contacts',
  deviceUseStatements: 'fhir-device-use-statements',
  appointments: 'fhir-appointments',
  binaries: 'fhir-binaries',
};
