import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const MEETLOCATIE_CODELIJST: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.4.4--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '40983000': {
        'nl-NL': 'Bovenarm',
        display: 'Upper arm structure',
      },
      '368209003': {
        'nl-NL': 'Rechter bovenarm',
        display: 'Right upper arm structure',
      },
      '368208006': {
        'nl-NL': 'Linker bovenarm',
        display: 'Left upper arm structure',
      },
      '68367000': {
        'nl-NL': 'Bovenbeen',
        display: 'Thigh structure',
      },
      '11207009': {
        'nl-NL': 'Rechter bovenbeen',
        display: 'Structure of right thigh',
      },
      '61396006': {
        'nl-NL': 'Linker bovenbeen',
        display: 'Structure of left thigh',
      },
      '8205005': {
        'nl-NL': 'Pols',
        display: 'Wrist region structure',
      },
      '9736006': {
        'nl-NL': 'Rechterpols',
        display: 'Structure of right wrist',
      },
      '5951000': {
        'nl-NL': 'Linkerpols',
        display: 'Structure of left wrist',
      },
      '7569003': {
        'nl-NL': 'Vinger',
        display: 'Finger structure',
      },
      '344001': {
        'nl-NL': 'Enkel',
        display: 'Ankle region structure',
      },
      '6685009': {
        'nl-NL': 'Rechterenkel',
        display: 'Structure of right ankle',
      },
      '51636004': {
        'nl-NL': 'Linkerenkel',
        display: 'Structure of left ankle',
      },
    },
  },
};
