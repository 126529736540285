import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';
import { Observable } from 'rxjs';
import { MenuItem, MenuService } from '../menu';
import { SideMenuComponent } from './side-menu.component';

@Component({
  standalone: true,
  selector: 'app-side-menu-container',
  template: `<app-side-menu [menu]="menu$ | async"></app-side-menu>`,
  imports: [SharedModule, NgbModalModule, SideMenuComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuContainerComponent {
  readonly menu$: Observable<MenuItem[] | undefined> = this.menuService.menu$;

  constructor(private readonly menuService: MenuService) {}
}
