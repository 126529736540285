import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType, UserAD } from '@digi.me/models';
import { addIf, addIfProperty } from '@globals';
import { CodeSystems } from '@hl7fhir/codesystems';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { BodyWeightEntryComponent } from './body-weight-entry/body-weight-entry.component';
import { WEIGHT_TEMPLATE } from './body-weight-entry/body-weight.template';
import { SelfMeasurementsMenuComponent } from './self-measurements-menu.component';
import { SelfMeasurementsModalService } from './services/self-measurements-modal.service';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-weight',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    SelfMeasurementsMenuComponent,
    ButtonComponent,
    HeaderComponent,
    BodyWeightEntryComponent,
    SharedModule,
  ],
  templateUrl: './self-measurements-modal-weight.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalWeightComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    weightControl: new FormControl('', Validators.required),
    unitControl: new FormControl('kg', Validators.required),
    clothingControl: new FormControl(''),
    additionalCommentsControl: new FormControl(''),
  });

  readonly user$ = this.store.select(selectUser);

  private service = inject(SelfMeasurementsModalService);

  constructor(private readonly store: Store) {}

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    const weight = this.form.value['weightControl'];
    const unit = this.form.value['unitControl'] ?? undefined;
    const clothingCode = this.form.value['clothingControl'] ?? undefined;
    const comment = this.form.value['additionalCommentsControl'] ?? undefined;

    const observation: r3.Observation = {
      ...WEIGHT_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...WEIGHT_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...WEIGHT_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      valueQuantity: {
        ...WEIGHT_TEMPLATE.valueQuantity,
        code: unit,
        unit,
        value: parseFloat(weight!),
      },
      component: [
        ...addIf(clothingCode !== undefined, {
          valueCodeableConcept: {
            coding: [
              {
                code: clothingCode,
                system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.8.1',
                display: BodyWeightEntryComponent.kledingCodelijstCodings.find((coding) => coding.code === clothingCode)
                  ?.display,
              },
            ],
          },
          code: {
            coding: [
              {
                code: '8352-7',
                display: 'Clothing worn during measure',
                system: 'http://loinc.org',
              },
            ],
          },
        }),
      ],
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: 'self-measurements',
        measurementType: MeasurementType.weight,
      })
    );

    this.close();
  }

  close() {
    this.onModalClose();

    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      weightControl: '',
      unitControl: 'kg',
      clothingControl: '',
      additionalCommentsControl: '',
    });
  }
}
