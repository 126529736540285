import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export type Language = 'en-US' | 'nl';

@Injectable({ providedIn: 'root' })
export class AppLanguageService {
  constructor(private readonly router: Router) {}

  /**
   * Changes the language of the application.
   * @param lang - The language code to change to.
   */
  changeLanguage(lang: string): void {
    $localize.locale = lang;
    const url = this.getLanguageRoute(lang);
    localStorage.setItem('locale', $localize.locale);

    // It will reload the page with the new language
    window.location.href = url;
  }

  /**
   * Returns the language route based on the specified locale.
   * The language route is constructed by appending the locale to the current router URL.
   *
   * @param locale - The locale to be used in the language route.
   * @returns The language route.
   */
  getLanguageRoute(locale: string): string {
    const url = this.router.url.split('/');
    url.splice(0, 2);
    return `/${locale}/${url.join('/')}`;
  }
}
