import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Connectable, Observable, ReplaySubject, connectable, filter, map } from 'rxjs';
import { MenuItem } from './menu-item.model';
import { getMenuConfig } from './menu.config';
import { findInMenuItems } from './menu.utils';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  readonly selectedMenuItemByUrl$: Observable<MenuItem | null>;
  readonly menu$: Observable<MenuItem[] | undefined>;

  readonly selectMeasurementMenuItem$: Observable<MenuItem | null>;

  constructor(router: Router) {
    const navigationEnd = router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd)
    ) as Observable<NavigationEnd>;

    // When switching pages the previous subscription is disposed leaving nobody that listens to the observable
    // Therefore we need to make it hot and replay the last navigation result.
    const url$ = navigationEnd.pipe(map((event) => event.urlAfterRedirects));

    const menu = connectable(
      url$.pipe(
        map((url) => {
          return this.findSideMenuItemsByUrl(
            [
              ...getMenuConfig().menuItems,
              {
                header: 'Home',
                path: `/${$localize.locale}/`,
              },
            ],
            url
          );
        })
      ),
      {
        connector: () => new ReplaySubject<MenuItem[] | undefined>(1),
      }
    );
    menu.connect();
    this.menu$ = menu;

    const selectedMenuItem: Connectable<MenuItem | null> = connectable(
      url$.pipe(map((url) => findInMenuItems(getMenuConfig().menuItems, url, null))),
      {
        connector: () => new ReplaySubject<MenuItem | null>(1),
      }
    );
    selectedMenuItem.connect();
    this.selectedMenuItemByUrl$ = selectedMenuItem;
    this.selectMeasurementMenuItem$ = selectedMenuItem.pipe(
      map((menuItem: MenuItem | null) =>
        menuItem?.path?.includes('/my-health-record/measurements/') ? menuItem : null
      )
    );
  }

  findSideMenuItemsByUrl(menuItems: MenuItem[], url: string, parent?: MenuItem): MenuItem[] | undefined {
    if (url === '/nl' || url === '/en-US') {
      return getMenuConfig().menuItems;
    }

    for (const item of menuItems) {
      if (item.path === url) {
        if (item.subMenu) {
          return [
            {
              ...item,
              expand: true,
              parent: parent ?? {
                path: `/${$localize.locale}/`,
                header: 'Home',
              },
            },
          ];
        } else {
          if (parent) {
            const subMenu: MenuItem[] = parent.subMenu!.map((subItem) => {
              return {
                ...subItem,
                selected: subItem.path === url,
              };
            });

            return [
              {
                ...parent,
                expand: true,
                subMenu,
              },
            ];
          } else {
            return getMenuConfig().menuItems.map((menuItem) => {
              return {
                ...menuItem,
                selected: menuItem.path === url,
              };
            });
          }
        }
      }

      if (item.subMenu) {
        const result = this.findSideMenuItemsByUrl(item.subMenu, url, item);

        if (result) {
          return result;
        }
      }
    }

    return undefined;
  }
}
