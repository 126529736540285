import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const AfwijkendAdemhalingspatroonCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.5.3--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '414563008': {
        'nl-NL': 'Ademhaling volgens Kussmaul',
        display: "Kussmaul's respiration",
      },
      '309155007': {
        'nl-NL': 'Ademhaling volgens Cheyne-Stokes',
        display: 'Cheyne-Stokes respiration',
      },
      '39106000': {
        'nl-NL': 'Atactische ademhaling',
        display: 'Ataxic respiration',
      },
      '70185004': {
        'nl-NL': 'Apneustische ademhaling',
        display: 'Apneustic breathing',
      },
      '7013003': {
        'nl-NL': 'Cluster ademhaling',
        display: 'Cluster breathing',
      },
      '1023001': {
        'nl-NL': 'Apneu',
        display: 'Apnoea',
      },
      '248586000': {
        'nl-NL': 'Verlengde expiratoire fase',
        display: 'Prolonged expiration',
      },
      '23141003': {
        'nl-NL': 'Happende ademhaling',
        display: 'Gasping respiration',
      },
    },
    'http://hl7.org/fhir/v3/NullFlavor': {
      OTH: {
        'nl-NL': 'Anders',
        display: 'Other',
      },
    },
  },
};
