import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getMaxDay, limitAndFormatDecimal, nameof } from '@globals';
import { Coding } from '@hl7fhir';
import { ValueSets } from '@hl7fhir/value-sets';
import { HARTSLAGMEETMETHODE_CODELIJST, HARTSLAGREGELMATIGHEID_CODELIJST } from '@hl7nl-fhir/value-sets';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-heart-and-pulse-rates-entry',
  templateUrl: './heart-and-pulse-rates-entry.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class HeartAndPulseRatesEntryComponent {
  public static hartslagMeetMethodeCodelistCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '113011001', display: 'Palpation' },
    { system: 'http://snomed.info/sct', code: '37931006', display: 'Auscultation' },
    { system: 'http://snomed.info/sct', code: '88140007', display: 'Cardiac monitoring' },
    { system: 'http://snomed.info/sct', code: '46825001', display: 'ECG' },
  ];

  public static hartslagRegelmatigheidCodelistCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '271636001', display: 'Heart regular' },
    { system: 'http://snomed.info/sct', code: '248650006', display: 'Heart irregular' },
  ];

  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  readonly hartslagMeetMethodeCodelist = HARTSLAGMEETMETHODE_CODELIJST;
  readonly translatedHartslagMeetMethodeCodelist: Coding[];
  readonly hartslagRegelmatigheidCodelist = HARTSLAGREGELMATIGHEID_CODELIJST;
  readonly translatedHartslagRegelmatigheidCodelist: Coding[];

  constructor() {
    this.translatedHartslagMeetMethodeCodelist =
      HeartAndPulseRatesEntryComponent.hartslagMeetMethodeCodelistCodings.map((coding) => {
        const display = ValueSets.getDisplay(coding, [HARTSLAGMEETMETHODE_CODELIJST]);
        return <Coding>{ ...coding, display: display.code };
      });

    this.translatedHartslagRegelmatigheidCodelist =
      HeartAndPulseRatesEntryComponent.hartslagRegelmatigheidCodelistCodings.map((coding) => {
        const display = ValueSets.getDisplay(coding, [HARTSLAGREGELMATIGHEID_CODELIJST]);
        return <Coding>{ ...coding, display: display.code };
      });
  }

  get maxDay(): string {
    return getMaxDay();
  }

  get dateControl() {
    return this.form.get(nameof<HeartAndPulseRatesEntryComponent>('dateControl'));
  }

  get heartRateControl() {
    return this.form.get(nameof<HeartAndPulseRatesEntryComponent>('heartRateControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  validateInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value = limitAndFormatDecimal(parseFloat(input.value), 999.99, 0.01);
    this.heartRateControl!.setValue(value);
  }
}
