import { Preferences } from '@capacitor/preferences';
import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

export class MobileOidcStorageService implements AbstractSecurityStorage {
  read(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  async write(key: string, value: string): Promise<void> {
    sessionStorage.setItem(key, value);

    await Preferences.set({
      key,
      value,
    });
  }

  async remove(key: string): Promise<void> {
    sessionStorage.removeItem(key);

    await Preferences.remove({ key });
  }

  async clear(): Promise<void> {
    sessionStorage.clear();

    // I don't think we should clear all preferences here as it might affect other parts of the app
    // await Preferences.clear();
  }
}
