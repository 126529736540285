import { HealthProfessionalRole, ResourceTypes } from '@globals';
import { Practitioner, PractitionerRole } from '@hl7fhir';
import { PractitionerRoleViewModel } from '@hl7fhir/resource-types';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import { selectPractitionerEntities } from '../practitioner/practitioner.selectors';
import { getResource } from '../utils';
import * as fromPractitionerRole from './practitioner-role-reducer';

export const selectPractitionerRoleState = createFeatureSelector<fromPractitionerRole.State>(
  features.FHIR.practitionerRoles
);

export const selectPractitionerRoleEntities = createSelector(
  selectPractitionerRoleState,
  fromPractitionerRole.selectPractitionerRolesEntities
);

export const selectAllPractitionerRoles = createSelector(
  selectPractitionerRoleState,
  fromPractitionerRole.selectAllPractitionerRoles
);

export const selectPractitionerRoleById = createSelector(
  selectRouteParams,
  selectPractitionerRoleEntities,
  ({ id }, practitionerRoles: Dictionary<PractitionerRole>) =>
    id && (practitionerRoles[id] ?? practitionerRoles[id.toLowerCase()] ?? practitionerRoles[id.toUpperCase()])
);

export const selectAllPractitionerRoleViewModels = createSelector(selectAllPractitionerRoles, (practionerRoles) =>
  practionerRoles.map((practitionerRole) => new PractitionerRoleViewModel(practitionerRole))
);

export const selectPractitionerRoleViewModelById = createSelector(
  selectPractitionerRoleById,
  (practitionerRole) => practitionerRole && new PractitionerRoleViewModel(practitionerRole)
);

export const selectHealthProfessionalRoles = createSelector(
  selectAllPractitionerRoles,
  selectPractitionerEntities,
  (practitionerRoles: PractitionerRole[], practitioners: Dictionary<Practitioner>) => {
    const resources: HealthProfessionalRole[] = [];

    practitionerRoles.forEach((practitionerRole: PractitionerRole) => {
      const practitioner = getResource<Practitioner>(
        ResourceTypes.practitioner,
        practitionerRole.practitioner,
        practitioners
      );

      resources.push({
        practitionerRole: practitionerRole,
        practitioner: practitioner,
      });
    });

    return resources;
  }
);

export const selectHealthProfessionalRolesOverviewViewModel = createSelector(
  selectHealthProfessionalRoles,
  (healthProfessionalRoles) => ({
    healthProfessionalRoles,
  })
);
