import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DigiMeModule } from '@digi.me';
import { ButtonComponent, HeaderComponent, WdxFooterComponent } from '@layout';
import { NgbAccordionModule, NgbDropdownModule, NgbOffcanvasModule } from '@ng-bootstrap/ng-bootstrap';
import { DigiMeStoreModule } from '@store/digi.me';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { SideMenuContainerComponent } from './side-menu/side-menu.container';
import { SideMobileMenuComponent } from './side-mobile-menu/side-mobile-menu.component';
import { HelpComponent, HelpDropDownMenuComponent, SettingsComponent } from './top-menu';
import { AddDropDownMenuContainerComponent } from './top-menu/add-drop-down-menu/add-drop-down-menu.container';
import { LogInButtonContainerComponent } from './top-menu/login-button/login-button.container';
import { MenuNavBarComponent } from './top-menu/menu-navbar.component';
import { MenuComponent } from './top-menu/menu.component';

@NgModule({
  exports: [SideMenuContainerComponent, HelpDropDownMenuComponent, MenuComponent, MenuNavBarComponent],
  declarations: [MenuComponent, SideMobileMenuComponent, MenuNavBarComponent, HelpDropDownMenuComponent],
  imports: [
    CommonModule,
    DigiMeModule,
    RouterModule,
    NgbDropdownModule,
    NgbOffcanvasModule,
    DigiMeStoreModule,
    NgbAccordionModule,
    SideMenuContainerComponent,
    LogInButtonContainerComponent,
    WdxFooterComponent,
    ButtonComponent,
    HelpComponent,
    SettingsComponent,
    HeaderComponent,
    SideMenuComponent,
    AddDropDownMenuContainerComponent,
  ],
})
export class NavigationModule {}
