import { Observation } from '@hl7fhir';

export interface DownloadStorageFileResponse {
  body: ReadableStream;
  contentLength?: number;
}

export interface StorageFile {
  id: string;
  name: string;
  originalName: string;
  originalPath: string;
  path: string;
}

export interface ListStorageFilesResponse {
  files: StorageFile[];
  total: number;
}

export type StorageResource = Observation;
