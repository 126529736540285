import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const JaNeeCodelijst: ValueSetFlattened = {
  id: 'v2-0136',
  systems: {
    'http://hl7.org/fhir/v2/0136': {
      N: {
        'nl-NL': 'Nee',
        display: 'No',
      },
      Y: {
        'nl-NL': 'Ja',
        display: 'Yes',
      },
    },
  },
};
