import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';

export const UnitOfMeasureCodes: CodeSystemFlattened = {
  id: 'unit-of-measure',
  codes: {
    '[in_i]': {
      display: 'inch',
    },
  },
};
