import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxDateNowValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const selectedDate = new Date(control.value);
    const now = new Date();

    return selectedDate > now ? { maxDate: { value: control.value } } : null;
  };
}
