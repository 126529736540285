<ng-template #modal
             let-modal>
  <div class="modal-header gap-2">
    <a *ngIf="canGoBack"
       (click)="previous()">
      <mat-icon fontIcon="arrow_back_ios_new"></mat-icon>
    </a>
    <div class="w-100">
      <ng-container *ngTemplateOutlet="headerToRender"></ng-container>
    </div>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="bodyToRender"></ng-container>
  </div>
  <div class="modal-footer">
    <div class="w-100">
      <ng-container *ngTemplateOutlet="footerToRender"></ng-container>
    </div>
  </div>
</ng-template>