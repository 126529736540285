import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../features';
import { reducer } from './digi-me.reducer';
import { DigiMeStorageEffects } from './effects/digi-me-storage.effects';
import { DigiMeEffects } from './effects/digi-me.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(features.DIGI_ME, reducer),
    EffectsModule.forFeature([DigiMeEffects, DigiMeStorageEffects]),
  ],
})
export class DigiMeStoreModule {}
