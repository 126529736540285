import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { getMaxDay, limitAndFormatDecimal, nameof } from '@globals';
import { Coding } from '@hl7fhir';
import { ValueSets } from '@hl7fhir/value-sets';
import { TEMPERATUURTYPE_CODELIJST } from '@hl7nl-fhir/value-sets';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-body-temperature-entry',
  templateUrl: './body-temperature-entry.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class BodyTemperatureEntryComponent {
  public static temperatureTypeCodelistCodings: Coding[] = [
    { system: 'http://snomed.info/sct', code: '307047009', display: 'Rectal temperature' },
    { system: 'http://snomed.info/sct', code: '415945006', display: 'Oral temperature' },
    { system: 'http://snomed.info/sct', code: '415882003', display: 'Axillary temperature' },
    { system: 'http://snomed.info/sct', code: '415929009', display: 'Groin temperature' },
    { system: 'http://snomed.info/sct', code: '415974002', display: 'Tympanic temperature' },
    { system: 'http://snomed.info/sct', code: '415922000', display: 'Forehead temperature' },
    { system: 'http://snomed.info/sct', code: '364246006', display: 'Temperature of vagina' },
    { system: 'http://snomed.info/sct', code: '698832009', display: 'Bladder temperature' },
    { system: 'http://snomed.info/sct', code: '276885007', display: 'Core body temperature' },
  ];

  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  readonly temperatureTypeCodelist = TEMPERATUURTYPE_CODELIJST;
  readonly translatedTemperatureTypeCodelist: Coding[];

  constructor() {
    this.translatedTemperatureTypeCodelist = BodyTemperatureEntryComponent.temperatureTypeCodelistCodings.map(
      (coding) => {
        const display = ValueSets.getDisplay(coding, [TEMPERATUURTYPE_CODELIJST]);
        return <Coding>{ ...coding, display: display.code };
      }
    );
  }

  get maxDay(): string {
    return getMaxDay();
  }

  get dateControl() {
    return this.form.get(nameof<BodyTemperatureEntryComponent>('dateControl'));
  }

  get temperatureControl() {
    return this.form.get(nameof<BodyTemperatureEntryComponent>('temperatureControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  validateInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value = limitAndFormatDecimal(parseFloat(input.value), 999_999.99, 0.01);
    this.temperatureControl!.setValue(value);
  }
}
