import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Directory, Encoding } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import {
  ApiService,
  DeviceService,
  ErrorHandlerService,
  FileSaverService,
  ShareMeasurementsService,
} from '@core/services';
import { DigiMeFile, ReadAccountsResponse, ReauthorizationDetails } from '@digi.me/models';
import { configId, environment } from '@environments/environment';
import { ErrorMessages, MobileFile, StorageKeys, StorageResource, WebFile, getLibraryFrom } from '@globals';
import { toAzureAdB2c } from '@language';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { APP_ACTIONS } from '@store/app';
import {
  OBSERVATION_UI_ACTIONS,
  SHARE_OBSERVATION_ACTIONS,
  createFhirActionFromDigiMeFiles,
  loadResourceFromStorageFiles,
} from '@store/hl7fhir';
import { LogoutAuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { TimeoutError, from, of, switchMap } from 'rxjs';
import {
  catchError,
  endWith,
  exhaustMap,
  filter,
  map,
  mergeAll,
  mergeMap,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { DOCUMENT_DOWNLOAD_ACTIONS, ERROR_UI_ACTIONS } from '../../app/actions/app.actions';
import {
  AUTHORIZE_URL_API_ACTIONS,
  BINARY_ACTIONS,
  CANCEL_SIGN_UP_API_ACTIONS,
  CLAIM_API_ACTIONS,
  DELETE_ACCOUNT_API_ACTIONS,
  DELETE_API_ACTIONS,
  DIGI_ME_SAAS_RETURN_ACTIONS,
  EXCHANGE_CODE_FOR_TOKEN_API_ACTIONS,
  FILES_API_ACTIONS,
  FILE_LIST_API_ACTIONS,
  FORCE_UPDATE_API_ACTIONS,
  PORTABILITY_REPORT_API_ACTIONS,
  READ_ACCOUNTS_API_ACTIONS,
  REAUTHORIZE_URL_API_ACTIONS,
  RESET_API_ACTIONS,
  REVOKE_URL_API_ACTIONS,
  SIGN_UP_API_ACTIONS,
  STORAGE_FILE_API_ACTIONS,
  UPDATE_AD_USER_API_ACTIONS,
  USER_AD_API_ACTIONS,
  USER_API_ACTIONS,
} from '../digi-me.actions';
import { selectBinaryFiles, selectFilesLoaded } from '../selectors';

@Injectable()
export class DigiMeEffects {
  // * Claim
  claim$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.claim),
        exhaustMap((claim) => of(CLAIM_API_ACTIONS.claimRequested({ library: claim.library })))
      );
    },
    { dispatch: true }
  );

  refreshAccountsOnRefreshCompleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FILES_API_ACTIONS.filesLoadSucceeded),
        filter((fhir) => fhir.trigger !== '' && fhir.trigger != null),
        exhaustMap(() => of(READ_ACCOUNTS_API_ACTIONS.accountsRequested()))
      );
    },
    { dispatch: true }
  );

  loadClaim$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CLAIM_API_ACTIONS.claimRequested),
        exhaustMap((claim) => {
          return this.apiService.claim(claim.library).pipe(
            map(() => CLAIM_API_ACTIONS.claimLoadSucceeded()),
            catchError((error) => of(CLAIM_API_ACTIONS.claimLoadFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  /**
   * Effect that cleans up signup flow data after a claim load failure or success.
   * It logs off the user locally and removes signup storage data, and then dispatches
   * the user authorization action.
   */
  cleanClaims$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CLAIM_API_ACTIONS.claimLoadFailed, CLAIM_API_ACTIONS.claimLoadSucceeded),
        tap(() => {
          this.oidcSecurityService.logoffLocal(`${configId}-signup`);
        }),
        exhaustMap(() => this.oidcSecurityService.setState('cleaned', `${configId}-signup`)),
        map(() => APP_ACTIONS.authenticationStarted({ skipLog: true }))
      );
    },
    { dispatch: true }
  );

  loadUserWhenAuthenticatedAndNotReturned$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticated),
        filter((authenticated) => authenticated.isReturn !== true),
        map(() => USER_API_ACTIONS.userDataRequested({ sourceFetch: false, trigger: 'loading' }))
      );
    },
    { dispatch: true }
  );

  loadUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_API_ACTIONS.userDataRequested),
        exhaustMap((action) => {
          return this.apiService.getUser().pipe(
            map((user) => USER_API_ACTIONS.userDataLoadSucceeded({ user: user, trigger: action.trigger })),
            catchError((error) => of(USER_API_ACTIONS.userDataLoadFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  loadedFileList$ = createEffect(
    () => {
      return this.apiService.fileList$.pipe(
        filter((fileList) => !!fileList),
        map((fileList) => FILE_LIST_API_ACTIONS.fileListLoadSucceeded({ fileList: fileList! }))
      );
    },
    { dispatch: true }
  );

  loadedStorageFileList$ = createEffect(
    () => {
      return this.apiService.fileList$.pipe(
        filter((fileList) => !!fileList),
        map((fileList) => FILE_LIST_API_ACTIONS.fileListLoadSucceeded({ fileList: fileList! }))
      );
    },
    { dispatch: true }
  );

  readAccountsIfAuthenticated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(USER_API_ACTIONS.userDataLoadSucceeded),
      exhaustMap((_) => of(READ_ACCOUNTS_API_ACTIONS.accountsRequested()))
    );
  });

  // * FILES
  loadFilesIfUserLoaded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_API_ACTIONS.userDataLoadSucceeded),
        exhaustMap((action) => of(FILES_API_ACTIONS.filesRequested({ sourceFetch: false, trigger: action.trigger })))
      );
    },
    { dispatch: true }
  );

  loadStorageFilesIfAdUserLoaded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_API_ACTIONS.userDataLoadSucceeded),
        exhaustMap(() => of(STORAGE_FILE_API_ACTIONS.storageFilesRequested()))
      );
    },
    { dispatch: true }
  );

  // * USER AD
  userADLoaded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_AD_API_ACTIONS.userADRequested),
        exhaustMap(() => {
          return this.apiService.getAdUser().pipe(
            map((userAd) => USER_AD_API_ACTIONS.userADSucceeded(userAd)),
            catchError((error) => of(USER_AD_API_ACTIONS.userADFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  userADLoading$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.authenticated),
        map(() => {
          return USER_AD_API_ACTIONS.userADRequested();
        })
      );
    },
    { dispatch: true }
  );

  // TODO Prevent loading multiple times, but now it is actually only done on app initialized
  loadFilesIfNotLoaded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FILES_API_ACTIONS.filesRequested),
        exhaustMap(({ sourceFetch, trigger, accountId }) => {
          return this.apiService.getFiles(sourceFetch, accountId).pipe(
            map((file: DigiMeFile) => createFhirActionFromDigiMeFiles(file, trigger)),
            catchError((error) => {
              if (error instanceof TimeoutError) {
                return of(
                  FILES_API_ACTIONS.filesLoadTimeout({
                    sourceFetch,
                    trigger,
                  })
                );
              }
              return of(FILES_API_ACTIONS.filesLoadFailed({ error }));
            }),
            endWith(
              FILES_API_ACTIONS.filesLoadSucceeded({
                sourceFetch,
                trigger,
              })
            )
          );
        })
      );
    },
    { dispatch: true }
  );

  loadStorageFiles$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(STORAGE_FILE_API_ACTIONS.storageFilesRequested),
        exhaustMap(() => {
          return this.apiService.getStorageFiles().pipe(
            map((file: StorageResource) => loadResourceFromStorageFiles(file)),
            catchError((error) => of(STORAGE_FILE_API_ACTIONS.storageFilesLoadFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  loadBinaryFiles$ = createEffect(
    () => {
      let binaryId: string | undefined = undefined;
      return this.actions$.pipe(
        ofType(BINARY_ACTIONS.requested),
        tap((action) => {
          binaryId = action.id;
        }),
        concatLatestFrom(() => this.store.select(selectBinaryFiles)),
        mergeMap(([_, files]) => {
          files ??= [];
          return from(files).pipe(
            withLatestFrom(this.store.select(selectFilesLoaded)),
            filter(([fileMeta, filesLoaded]) => !filesLoaded.includes(fileMeta.name)),
            map(([fileMeta, _]) => this.apiService.getFile(fileMeta.name)),
            mergeAll(1),
            map((response: HttpResponse<DigiMeFile>) => response.body!),
            map((file) => createFhirActionFromDigiMeFiles(file, 'loading')),
            takeWhile(
              (resources) => binaryId !== undefined && !resources.binaries.some((binary) => binary.id === binaryId),
              true
            )
          );
        })
      );
    },
    { dispatch: true }
  );

  // * (RE)AUTHORIZATION
  getAuthorizeUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested),
      exhaustMap(({ createNewUser, sourceFetch, sourceType }) => {
        return this.apiService.getAuthorizeUrl(environment.scheme, createNewUser, sourceFetch, sourceType).pipe(
          map((url) => AUTHORIZE_URL_API_ACTIONS.authorizeUrlLoadSucceeded({ url })),
          catchError((error) => of(AUTHORIZE_URL_API_ACTIONS.authorizeUrlLoadFailed({ error })))
        );
      })
    );
  });

  getRevoke$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(REVOKE_URL_API_ACTIONS.revokeRequested),
      exhaustMap((action) => {
        return this.apiService.getRevoke(action.accountId, environment.scheme).pipe(
          map((response) => REVOKE_URL_API_ACTIONS.revokeLoadSucceeded({ url: response.location })),
          catchError((error) => of(REVOKE_URL_API_ACTIONS.revokeLoadFailed({ error })))
        );
      })
    );
  });

  deleteAccount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DELETE_ACCOUNT_API_ACTIONS.deleteAccountRequested),
        exhaustMap((action) => {
          return this.apiService.deleteAccount(action.accountId).pipe(
            map(() => DELETE_ACCOUNT_API_ACTIONS.deleteAccountSucceeded()),
            catchError((error) => of(DELETE_ACCOUNT_API_ACTIONS.deleteAccountFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  updateADUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UPDATE_AD_USER_API_ACTIONS.updateADUserRequested),
        exhaustMap((action) => {
          return this.apiService.updateAdUser(action).pipe(
            map((response) => UPDATE_AD_USER_API_ACTIONS.updateADUserSucceeded(response)),
            catchError((error) => of(UPDATE_AD_USER_API_ACTIONS.updateADUserFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  deleteAccountSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DELETE_ACCOUNT_API_ACTIONS.deleteAccountSucceeded),
        tap(() => {
          if (Capacitor.isNativePlatform()) {
            // Removes all plugins listeners because the app is going to be reloaded and the listeners will be added
            // again. Otherwise, memory leaks occur.
            App.removeAllListeners();

            // Navigate to the linked sources page
            localStorage.setItem('skipHomepage', 'true');
          }

          window.location.reload();
        })
      );
    },
    { dispatch: false }
  );

  navigateToRevokeUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(REVOKE_URL_API_ACTIONS.revokeLoadSucceeded),
        tap((action) => {
          window.location.href = action.url;
        })
      );
    },
    { dispatch: false }
  );

  navigateToOnboardUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AUTHORIZE_URL_API_ACTIONS.authorizeUrlLoadSucceeded),
        tap((action) => {
          this.deviceService.copyToLocalStorage();
          this.saveSourceType(action.url.authorizationUrl);
          window.location.href = action.url.authorizationUrl;
        })
      );
    },
    { dispatch: false }
  );

  reauthorize$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(REAUTHORIZE_URL_API_ACTIONS.reauthorizeRequested),
        exhaustMap(({ accountId }) => {
          return this.apiService.getReauthorizeUrl(accountId, environment.scheme).pipe(
            map((details: ReauthorizationDetails) => REAUTHORIZE_URL_API_ACTIONS.reauthorizeLoadSucceeded({ details })),
            catchError((error) => of(REAUTHORIZE_URL_API_ACTIONS.reauthorizeLoadFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  cancelSignUp$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CANCEL_SIGN_UP_API_ACTIONS.cancelSignUpStarted),
        map(() => {
          localStorage.removeItem(StorageKeys.SHOULD_CREATE_ACCOUNT);
          this.deviceService.removeDeviceId();
          return CANCEL_SIGN_UP_API_ACTIONS.cancelSignUpSucceeded();
        })
      );
    },
    { dispatch: true }
  );

  navigateToOnboard$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CANCEL_SIGN_UP_API_ACTIONS.cancelSignUpSucceeded),
        tap(() => {
          window.location.href = `/${$localize.locale}`;

          // * Navigating to the specified href does not seem to reload the app
          // * in the browser, so we have to do it manually.
          if (Capacitor.isNativePlatform()) {
            // Removes all plugins listeners because the app is going to be reloaded and the listeners will be added
            // again. Otherwise, memory leaks occur.
            App.removeAllListeners();

            // Navigate to the linked sources page
            localStorage.setItem('skipHomepage', 'true');

            window.location.reload();
          }
        })
      );
    },
    { dispatch: false }
  );

  portabilityReportUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PORTABILITY_REPORT_API_ACTIONS.portabilityReportRequested),
        exhaustMap(({ from, to }) => {
          return this.apiService.getPortabilityReportUrl(from, to).pipe(
            map((report) => PORTABILITY_REPORT_API_ACTIONS.portabilityReportLoadSucceeded({ report })),
            catchError((error) => of(PORTABILITY_REPORT_API_ACTIONS.portabilityReportLoadFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  portabilityReport$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(PORTABILITY_REPORT_API_ACTIONS.portabilityReportLoadSucceeded),
        tap(async ({ report }) => {
          const file = {
            data: report.file,
            name: 'report.xml',
          };

          const platformSpecificFile = Capacitor.isNativePlatform()
            ? <MobileFile>{ ...file, path: file.name, directory: Directory.Cache, encoding: Encoding.UTF8 }
            : <WebFile>{ ...file, type: 'text/xml' };

          await this.fileSaverService.save(platformSpecificFile);
        })
      );
    },
    { dispatch: false }
  );

  navigateToReauthorizeUrl$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(REAUTHORIZE_URL_API_ACTIONS.reauthorizeLoadSucceeded),
        tap(({ details }) => {
          window.location.href = details.reauthorizeUrl;
        })
      );
    },
    { dispatch: false }
  );

  readAccountsIfNotLoaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(READ_ACCOUNTS_API_ACTIONS.accountsRequested),
      exhaustMap(() =>
        this.apiService.readAccounts().pipe(
          map((accounts: ReadAccountsResponse) => READ_ACCOUNTS_API_ACTIONS.accountsLoadSucceeded({ accounts })),
          catchError((error) => of(READ_ACCOUNTS_API_ACTIONS.accountsLoadFailed({ error })))
        )
      )
    );
  });

  identifySharingProvider$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(READ_ACCOUNTS_API_ACTIONS.accountsLoadSucceeded),
        concatLatestFrom(() => this.shareMeasurementsService.accountReference$),
        filter(([, accountReference]) => !!accountReference),
        tap(() => this.router.navigate([`${$localize.locale}`, 'share'])),
        map(([action, accountReference]) => {
          const account = action.accounts.accounts.find((account) => account.reference === accountReference);

          // ! TODO: Handle no account found
          return SHARE_OBSERVATION_ACTIONS.findAccountSucceeded({
            account: account!,
          });
        })
      );
    },
    { dispatch: true }
  );

  reset$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RESET_API_ACTIONS.resetRequested),
        exhaustMap(() => {
          return this.apiService.reset().pipe(
            map(() => RESET_API_ACTIONS.resetSucceeded()),
            catchError((error) => of(RESET_API_ACTIONS.resetFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  resetSuccessRefreshApp$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(RESET_API_ACTIONS.resetSucceeded),
        tap(() => (window.location.href = `/${$localize.locale}`))
      );
    },
    { dispatch: false }
  );

  resetAccount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DELETE_API_ACTIONS.deleteRequested),
        exhaustMap(() => {
          return this.apiService.reset(true).pipe(
            map(() => DELETE_API_ACTIONS.deleteSucceeded()),
            catchError((error) => of(DELETE_API_ACTIONS.deleteFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  deletedAccount$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DELETE_API_ACTIONS.deleteSucceeded),
        tap(async () => {
          if (Capacitor.isNativePlatform()) {
            // If account is deleted no need to keep stored data anymore
            await Preferences.clear();
          }

          const logoutOptions: LogoutAuthOptions = {
            urlHandler: (_url: string) => {
              return '';
            },
          };

          this.oidcSecurityService.logoffAndRevokeTokens(configId, logoutOptions).subscribe();
          this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-signup`, logoutOptions).subscribe();
          this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-reset`, logoutOptions).subscribe();
          this.oidcSecurityService.logoffLocalMultiple();
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Effect that handles the action when onboarding service is successful.
   * It exchanges the code for a token, performs signup if library is available,
   * and navigates to the home page or dispatches filesRequested action.
   */
  authorizeDigiMeSaasSucceeded$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeSucceeded),
        exhaustMap(({ params }) => {
          const library = getLibraryFrom(params);
          return this.apiService.exchangeCodeForToken(params['code'], `${params['success']}`, library).pipe(
            switchMap((httpResponse: HttpResponse<any>) => {
              if (httpResponse.status === 201 || httpResponse.status === 200) {
                const library = httpResponse.body?.library;

                if (library) {
                  // Signup
                  this.oidcSecurityService.setState(library, `${configId}-signup`).subscribe();
                  this.router.navigate([`${$localize.locale}`]);
                  return of(SIGN_UP_API_ACTIONS.signUpAsked({ library }));
                }

                return from(this.router.navigate([`${$localize.locale}`, 'linked-sources'])).pipe(
                  switchMap(() =>
                    of(USER_API_ACTIONS.userDataRequested({ sourceFetch: false, trigger: params['accountReference'] }))
                  )
                );
              }

              return of(EXCHANGE_CODE_FOR_TOKEN_API_ACTIONS.exchangeCodeForTokenFailed({ httpResponse }));
            })
          );
        })
      );
    },
    { dispatch: true }
  );

  signUpStarted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SIGN_UP_API_ACTIONS.signUpStarted),
        tap(() => {
          this.oidcSecurityService.authorize(`${configId}-signup`, {
            customParams: { ui_locales: toAzureAdB2c($localize.locale), prompt: 'login' },
          });
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Effect that handles errors during onboarding process and any other action returning to the return page.
   * the 'success' query parameter is set to 'false', and the 'errorCode' query parameter
   * is one of the values defined in the OnboardErrorCodes enum.
   * If the conditions are met, it navigates to the '/linked-sources' route, replacing the current URL.
   * Finally, it dispatches actions to show an error message and request files.
   */
  authorizeDigiMeSaasFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DIGI_ME_SAAS_RETURN_ACTIONS.authorizeFailed, ERROR_UI_ACTIONS.generalFailure),
        switchMap(({ params }) =>
          from(this.router.navigate([`${$localize.locale}`, 'linked-sources'], { replaceUrl: true })).pipe(
            switchMap((_) =>
              of(
                ERROR_UI_ACTIONS.showErrorMessage({
                  code: params['errorCode'] || 'Unknown',
                }),
                USER_API_ACTIONS.userDataRequested({ sourceFetch: false, trigger: 'loading' })
              )
            )
          )
        )
      );
    },
    { dispatch: true }
  );

  /**
   * Effect that handles the action when exchanging code for token fails.
   * It taps into the action stream, handles the error response, and does not dispatch any further actions.
   */
  exchangeCodeForTokenFailed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(EXCHANGE_CODE_FOR_TOKEN_API_ACTIONS.exchangeCodeForTokenFailed),
        tap(({ httpResponse }) => {
          this.errorHandlerService.handleError(httpResponse.status, ErrorMessages.EXCHANGE_CODE_FOR_TOKEN_FAILED);
        })
      );
    },
    { dispatch: false }
  );

  /**
   * Reauthorize succeeded. Confirm the implicit session and load the files.
   * For now no account reference is given for the reauthorized source, so we display a general loader.
   */
  confirmReauthorizeImplicitSession$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DIGI_ME_SAAS_RETURN_ACTIONS.reauthorizeCompleted),
        exhaustMap(
          (_) =>
            this.apiService
              .confirm()
              .pipe(map((_) => USER_API_ACTIONS.userDataRequested({ sourceFetch: false, trigger: 'loading' }))) // TODO Get reauthenticated source id
        )
      );
    },
    { dispatch: true }
  );

  /**
   * Onboard succeeded. Confirm the implicit session and load the files with the account reference of the just onboarded source.
   */
  confirmOnboardImplicitSession$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DIGI_ME_SAAS_RETURN_ACTIONS.onboardSucceeded),
        exhaustMap((action) =>
          this.apiService.confirm().pipe(
            map((_) =>
              USER_API_ACTIONS.userDataRequested({
                sourceFetch: false,
                trigger: action.params['accountReference'] ?? 'loading',
              })
            )
          )
        )
      );
    },
    { dispatch: true }
  );

  /**
   * Effect that handles the action when the manage permissions process is cancelled.
   */
  managePermissionsCancelled$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DIGI_ME_SAAS_RETURN_ACTIONS.managePermissionsCancelled),
        tap(async () => await this.router.navigate([`${$localize.locale}`, 'linked-sources'])),
        map(() => USER_API_ACTIONS.userDataRequested({ sourceFetch: false, trigger: 'loading' }))
      );
    },
    { dispatch: true }
  );

  showBinaryNotFoundError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(DOCUMENT_DOWNLOAD_ACTIONS.downloadFailure),
        map((_) => ERROR_UI_ACTIONS.showErrorMessage({ code: 'BINARY_NOT_FOUND' }))
      );
    },
    { dispatch: true }
  );

  /**
   * Force update the Mobile App.
   */
  forceUpdate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(FORCE_UPDATE_API_ACTIONS.forceUpdateRequested),
        filter(() => Capacitor.isNativePlatform()),
        exhaustMap(() => {
          return this.apiService.getVersionCheck().pipe(
            map((response) => {
              return FORCE_UPDATE_API_ACTIONS.forceUpdateSucceeded({ mobileVersionDetails: response });
            }),
            catchError((error) => of(FORCE_UPDATE_API_ACTIONS.forceUpdateFailed({ error })))
          );
        })
      );
    },
    { dispatch: true }
  );

  // * EXCEPTION HANDLING
  sendException$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          READ_ACCOUNTS_API_ACTIONS.accountsLoadFailed,
          FILES_API_ACTIONS.filesLoadFailed,
          AUTHORIZE_URL_API_ACTIONS.authorizeUrlLoadFailed,
          FILE_LIST_API_ACTIONS.fileListLoadFailed,
          REAUTHORIZE_URL_API_ACTIONS.reauthorizeLoadFailed,
          PORTABILITY_REPORT_API_ACTIONS.portabilityReportLoadFailed,
          USER_API_ACTIONS.userDataLoadFailed,
          APP_ACTIONS.authenticationError,
          DELETE_ACCOUNT_API_ACTIONS.deleteAccountFailed,
          REVOKE_URL_API_ACTIONS.revokeLoadFailed,
          OBSERVATION_UI_ACTIONS.removeFailed,
          FORCE_UPDATE_API_ACTIONS.forceUpdateFailed
        ),
        tap((error) => {
          this.errorHandlerService.handleError(error.error, error.type);
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly router: Router,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly deviceService: DeviceService,
    private readonly shareMeasurementsService: ShareMeasurementsService,
    private readonly store: Store,
    private readonly fileSaverService: FileSaverService
  ) {}

  /**
   * Save the sourceType to local storage.
   * Based on the sourceType, we can determine whether the user is sharing data.
   * For now, we assume that the user is sharing data if the sourceType is set to 'push'.
   * @param url - The URL to extract the sourceType from.
   */
  saveSourceType(url: string) {
    const urlObj = new URL(url);
    const sourceType = urlObj.searchParams.get('sourceType');
    localStorage.setItem('sourceType', sourceType!);
  }
}
