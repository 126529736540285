import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
//import { STORAGE_FILE_API_ACTIONS } from '../../digi.me/digi-me.actions';
import { catchError, exhaustMap, from, map, of, switchMap } from 'rxjs';
import { OBSERVATION_UI_ACTIONS } from './observation.actions';

@Injectable()
export class ObservationEffects {
  // * Remove an observation
  remove$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(OBSERVATION_UI_ACTIONS.remove),
        exhaustMap((data) => {
          return this.apiService.deleteStorageFile(`/self-measurements/${data.id}.json`).pipe(
            switchMap(() => {
              return from(this.router.navigate([`${$localize.locale}/${data.navigateTo}`])).pipe(
                map(() => OBSERVATION_UI_ACTIONS.removeSuccess({ id: data.id }))
              );
            }),
            catchError((error) => {
              return of(OBSERVATION_UI_ACTIONS.removeFailed({ error }));
            })
          );
        })
      );
    },
    { dispatch: true }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly apiService: ApiService
  ) {}
}
