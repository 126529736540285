// This file can be replaced during build by using the `fileReplacements` array.

import { LogLevel, OpenIdConfiguration } from 'angular-auth-oidc-client';

export const configId = 'azureb2c-wdx-local';
export function getOpenIdConfigs() {
  return {
    openIdConfigs: [
      <OpenIdConfiguration>{
        configId: `${configId}-signup`,
        authority: 'https://wdxnonprod.b2clogin.com/wdxnonprod.onmicrosoft.com/B2C_1_SIGNUP/v2.0',
        authWellknownEndpointUrl:
          'https://wdxnonprod.b2clogin.com/wdxnonprod.onmicrosoft.com/B2C_1_SIGNUP/v2.0/.well-known/openid-configuration',
        clientId: '0880c53d-5401-43f0-9517-0223265fc440',
        scope: 'openid profile offline_access https://wdxnonprod.onmicrosoft.com/wdx/API.ALL',
        responseType: 'code',
        redirectUrl: `${window.location.origin}/${$localize.locale}/callback`,
        postLogoutRedirectUri: `${window.location.origin}/${$localize.locale}`,
        forbiddenRoute: `/${$localize.locale}/forbidden`,
        postLoginRoute: `/${$localize.locale}/callback`, // Use the same page to prevent flickering of the UI
        unauthorizedRoute: `/${$localize.locale}/onboard-or-log-in`,
        useRefreshToken: true,
        silentRenew: true,
        ignoreNonceAfterRefresh: true,
        autoUserInfo: false,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        logLevel: LogLevel.Debug,
        autoCleanStateAfterAuthentication: false,
        secureRoutes: [`/api/claim`],
      },
      <OpenIdConfiguration>{
        configId: configId,
        authority: 'https://wdxnonprod.b2clogin.com/wdxnonprod.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/v2.0',
        authWellknownEndpointUrl:
          'https://wdxnonprod.b2clogin.com/wdxnonprod.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN/v2.0/.well-known/openid-configuration',
        clientId: '0880c53d-5401-43f0-9517-0223265fc440',
        scope: 'openid profile offline_access https://wdxnonprod.onmicrosoft.com/wdx/API.ALL',
        responseType: 'code',
        redirectUrl: `${window.location.origin}/${$localize.locale}/callback`,
        postLogoutRedirectUri: `${window.location.origin}/${$localize.locale}`,
        forbiddenRoute: `/${$localize.locale}/forbidden`,
        postLoginRoute: `/${$localize.locale}`,
        unauthorizedRoute: `/${$localize.locale}/onboard-or-log-in`,
        useRefreshToken: true,
        silentRenew: true,
        ignoreNonceAfterRefresh: true,
        autoUserInfo: false,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        logLevel: LogLevel.Debug,
        secureRoutes: [`/api/`],
      },
      <OpenIdConfiguration>{
        configId: `${configId}-reset`,
        authority: 'https://wdxnonprod.b2clogin.com/wdxnonprod.onmicrosoft.com/B2C_1_RESET/v2.0',
        authWellknownEndpointUrl:
          'https://wdxnonprod.b2clogin.com/wdxnonprod.onmicrosoft.com/B2C_1_RESET/v2.0/.well-known/openid-configuration',
        clientId: '0880c53d-5401-43f0-9517-0223265fc440',
        scope: 'openid profile offline_access https://wdxnonprod.onmicrosoft.com/wdx/API.ALL',
        responseType: 'code',
        redirectUrl: `${window.location.origin}/${$localize.locale}/callback`,
        postLogoutRedirectUri: `${window.location.origin}/${$localize.locale}`,
        forbiddenRoute: `/${$localize.locale}/forbidden`,
        postLoginRoute: `/${$localize.locale}/settings`,
        unauthorizedRoute: `/${$localize.locale}/settings`,
        useRefreshToken: true,
        silentRenew: true,
        ignoreNonceAfterRefresh: true,
        autoUserInfo: false,
        maxIdTokenIatOffsetAllowedInSeconds: 600,
        logLevel: LogLevel.Debug,
        autoCleanStateAfterAuthentication: false,
        secureRoutes: [`/api/`],
      },
    ],
  };
}

export const environment = {
  apiRoot: '/api',
  version: '1.4.0-rc.36',
  appName: 'digi.me',
  scheme: false,
  production: false,
  mixPanel: {
    enabled: false,
  },
  freshdeskWidget: {
    widgetId: '9000000884',
  },
  appInsights: {
    enabled: false,
    instrumentationKey: '',
  },
  sessionTimeout: 3600,
  features: {
    healthCareProvider: true,
    patientContacts: true,
    flags: true,
    healthProfessional: true,
    insurance: true,
    functionalOrMentalStatus: true,
    consent: true,
    livingSituation: true,
    linkedSources: true,
    ips: true,
    opt: true,
    appointment: true,
    encounter: true,
    allergyIntolerance: true,
    bodyWeight: true,
    bodyHeight: true,
    bodyTemperature: true,
    bloodPressure: true,
    heartAndPulseRates: true,
    glucose: true,
    o2saturations: true,
    respiratory: true,
    diagnoses: true,
    documents: true,
    vaccinations: true,
    results: true,
    drugUse: true,
    tobaccoUse: true,
    alcoholUse: true,
    medication: true,
    procedures: true,
    episodeOfCare: true,
    intoxications: true,
    lifeStyle: true,
    nutritionAdvice: true,
    laboratory: true,
    journal: true,
    encounters: true,
    reauthorize: true,
    medicalAids: true,
    imaging: true,
    lokalise: {
      enabled: false,
    },
    measurements: {
      add: true,
      share: true,
    },
    selfMeasurements: {
      bodyWeight: true,
      bodyHeight: true,
      bodyTemperature: true,
      bloodPressure: true,
      heartAndPulseRates: true,
      glucose: true,
      o2saturations: true,
      respiratory: true,
    },
    patientSummary: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
