import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType } from '@digi.me/models';
import { addIf, addIfProperty } from '@globals';
import { Coding } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { ValueSets } from '@hl7fhir/value-sets';
import { JaNeeCodelijst } from '@hl7nl-fhir/value-sets';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { UserAD } from '../digi.me/models/ad-user.model';
import { BloodOxygenEntryComponent } from './blood-oxygen-entry/blood-oxygen-entry.component';
import { BLOOD_OXYGEN_TEMPLATE } from './blood-oxygen-entry/blood-oxygen.template';
import { SelfMeasurementsMenuComponent } from './self-measurements-menu.component';
import { SelfMeasurementsModalService } from './services/self-measurements-modal.service';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-blood-oxygen',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    SelfMeasurementsMenuComponent,
    ButtonComponent,
    HeaderComponent,
    SharedModule,
    BloodOxygenEntryComponent,
  ],
  templateUrl: './self-measurements-modal-blood-oxygen.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalBloodOxygenComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly jaNeeCodelijst = JaNeeCodelijst;
  readonly translatedJaNeeCodelijst: Coding[];

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    bloodOxygenControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(100)]),
    oxygenAdministeredControl: new FormControl(''),
    additionalCommentsControl: new FormControl(''),
  });

  readonly user$ = this.store.select(selectUser);

  private service = inject(SelfMeasurementsModalService);

  constructor(private readonly store: Store) {
    this.translatedJaNeeCodelijst = BloodOxygenEntryComponent.jaNeeCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [this.jaNeeCodelijst]);
      return <Coding>{ ...coding, display: display.code };
    });
  }

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    const bloodOxygenControl = this.form.value['bloodOxygenControl'];
    const oxygenAdministeredControl = this.form.value['oxygenAdministeredControl'] ?? undefined;
    const comment = this.form.value['additionalCommentsControl'] ?? undefined;

    const observation: r3.Observation = {
      ...BLOOD_OXYGEN_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...BLOOD_OXYGEN_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...BLOOD_OXYGEN_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      valueQuantity: {
        value: parseFloat(bloodOxygenControl!),
        unit: '%O2',
        system: 'http://unitsofmeasure.org',
        code: '%',
      },
      code: {
        coding: [
          {
            system: 'http://loinc.org',
            code: '2708-6',
            display: 'Oxygen saturation in Arterial blood',
          },
          {
            system: 'http://loinc.org',
            code: '59408-5',
            display: 'Oxygen saturation in Arterial blood by Pulse oximetry',
          },
        ],
      },
      component: [
        ...addIf(!!oxygenAdministeredControl, {
          code: {
            coding: [
              {
                code: '74206-4',
                system: 'http://loinc.org',
                display: 'Oxygen therapy was given [NTDS]',
              },
            ],
          },
          valueCodeableConcept: {
            coding: [
              {
                code: oxygenAdministeredControl,
                system: 'http://hl7.org/fhir/v2/0136',
                display: this.translatedJaNeeCodelijst.find((coding) => coding.code === oxygenAdministeredControl)
                  ?.display,
              },
            ],
          },
        }),
      ],
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: 'self-measurements',
        measurementType: MeasurementType.o2Saturation,
      })
    );

    this.close();
  }

  close() {
    this.onModalClose();

    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      bloodOxygenControl: '',
      oxygenAdministeredControl: '',
      additionalCommentsControl: '',
    });
  }
}
