<ng-container *ngFor="let item of menu">

  @if(item.placeholder) {

  <div class="d-flex justify-content-center">
    <hr class="w-75 delimeter">
  </div>

  } @else {

  <app-button use="menu"
              type="navigation"
              [svg]="item.expand ? 'back-arrow' : item.icon"
              [selected]="item.selected === true"
              [label]="item.parent?.header ?? item.header"
              (clicked)="select(item.parent ?? item)"></app-button>

  <app-side-menu *ngIf="item.expand"
                 [menu]="item.subMenu"></app-side-menu>
  }

</ng-container>