import * as r3 from 'fhir/r3';

export const WEIGHT_TEMPLATE: r3.Observation = {
  resourceType: 'Observation',

  id: '$UUID_CREATED_FOR_THE_RESOURCE',
  identifier: [
    {
      system: 'urn:oid:$OID_FOR_DM_PRODUCT',
      value: 'urn:uuid:UUID_CREATED_FOR_THE_RESOURCE',
    },
  ],
  effectiveDateTime: '$DATETIME_COLLECTED_FROM_USER',
  category: [
    {
      coding: [
        {
          display: 'Vital Signs',
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'vital-signs',
        },
      ],
    },
  ],
  subject: {
    display: 'Patient',
    reference: 'urn:uuid:$UUID_FOR_PATIENT',
  },
  performer: [
    {
      display: 'Patient',
      reference: 'urn:uuid:$UUID_FOR_PATIENT',
    },
  ],
  // comment: '$COMMENT_COLLECTED_FROM_USER',
  status: 'final',

  meta: {
    profile: [
      'http://nictiz.nl/fhir/StructureDefinition/zib-BodyWeight',
      'http://hl7.org/fhir/3.0/StructureDefinition/Observation',
    ],
  },

  valueQuantity: {
    code: '$UNIT',
    value: <any>'$VALUE',
    unit: '$UNIT',
    system: 'http://unitsofmeasure.org',
  },
  component: [
    // {
    //   valueCodeableConcept: {
    //     coding: [
    //       {
    //         code: '$CLOTHING_CODE',
    //         system: 'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.8.1',
    //         display: '$CLOTHING_DISPLAY',
    //       },
    //     ],
    //   },
    //   code: {
    //     coding: [
    //       {
    //         code: '8352-7',
    //         display: 'Clothing worn during measure',
    //         system: 'http://loinc.org',
    //       },
    //     ],
    //   },
    // },
  ],
  code: {
    coding: [
      {
        display: 'Body weight',
        system: 'http://loinc.org',
        code: '29463-7',
      },
    ],
  },
};
