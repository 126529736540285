import { Injectable } from '@angular/core';
import { configId } from '@environments/environment';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { concatMap, delay, exhaustMap, filter, map, mergeMap, switchMap, tap } from 'rxjs';
// The same issue with imports as in mixpanel.service.ts
// ! TODO We need to address and resolve this issue.
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { AuthenticationService } from '@core/services';
import { PreferencesKeys } from '@globals';
import { toAzureAdB2c } from '@language';
import { DeviceService } from '../../core/services/device.service';
import { APP_ACTIONS } from '../app/actions/app.actions';
import { USER_ACTIONS } from './user.actions';

@Injectable({
  providedIn: 'root',
})
export class UserEffects {
  /**
   * Effect that handles the sign out action.
   * Removes the device ID, logs off and revokes tokens for the current user.
   */
  signOut$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signOut),
        delay(500),
        concatMap(() => {
          // Set a timeout to dispatch the 'sign_out' event using Mixpanel before initiating the user's logoff process.
          // This ensures that the 'sign_out' event is dispatched prior to the user being logged off. If the user is logged
          // off before the 'sign_out' event is dispatched, the event will not be sent. This occurs because the user is logged
          // off before the HTTP request to Mixpanel is completed, resulting in the request being canceled.
          this.deviceService.removeDeviceId();
          this.deviceService.removeFromLocalStorage();

          return this.oidcSecurityService
            .logoffAndRevokeTokens(configId)
            .pipe(mergeMap(() => this.oidcSecurityService.logoffAndRevokeTokens(`${configId}-signup`)));
        }),
        map(() => USER_ACTIONS.signOutSucceeded())
      );
    },
    {
      dispatch: true,
    }
  );

  resetBiometricsConfig$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signOutSucceeded, APP_ACTIONS.timeoutLogout),
        filter(() => Capacitor.isNativePlatform()),
        tap(async () => {
          await Preferences.remove({ key: configId });

          // This configs are  also stored by oidc-lib.
          await Preferences.remove({ key: `${configId}-reset` });
          await Preferences.remove({ key: `${configId}-signup` });

          await Preferences.remove({ key: PreferencesKeys.ENABLE_BIOMETRICS });
          await Preferences.remove({ key: PreferencesKeys.HIDE_USE_BIOMETRICS_MODAL });
        })
      );
    },
    { dispatch: false }
  );

  signUp$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signUpWithoutLibClaim),
        tap(() => {
          const customParams: { [param: string]: string } = {
            ui_locales: toAzureAdB2c($localize.locale),
          };

          this.oidcSecurityService.authorize(`${configId}-signup`, { customParams });
        })
      );
    },
    { dispatch: false }
  );

  authenticate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(USER_ACTIONS.signUpSucceeded),
        tap(() => {
          this.oidcSecurityService.logoffLocal(`${configId}-signup`);
        }),
        exhaustMap(() => this.oidcSecurityService.setState('cleaned', `${configId}-signup`)),
        map(() => APP_ACTIONS.authenticationStarted({ skipLog: true }))
      );
    },
    { dispatch: true }
  );

  trySessionRefreshIfBiometricsAreEnabled$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(APP_ACTIONS.unauthenticated),
        // Check if the platform is iOS or Android
        filter((_) => Capacitor.isNativePlatform()),
        // Check if the user has enabled biometrics
        // Then we can assume that the refresh token exists in the storage
        switchMap((_) => this.authenticationService.useBiometrics$),
        filter((useBiometrics: boolean) => useBiometrics),
        // Try to refresh the session
        exhaustMap((_) => this.oidcSecurityService.forceRefreshSession(undefined, configId)),
        // Check again if the user is authenticated with the new credentials
        switchMap((_) => this.oidcSecurityService.checkAuth(`${window.location.origin}`, configId)),
        filter((loginResponse: LoginResponse) => loginResponse.isAuthenticated),
        map((_) => APP_ACTIONS.authenticated({}))
      );
    },
    { dispatch: true }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly deviceService: DeviceService,
    private readonly authenticationService: AuthenticationService
  ) {}
}
