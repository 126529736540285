export interface MenuItem {
  header?: string;
  headerTopMenu?: string;
  path?: string;
  icon?: string;
  subMenu?: MenuItem[];
  expand?: boolean;
  parent?: MenuItem | null;
  placeholder?: boolean;
  selected?: boolean;
}
