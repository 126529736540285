<nav [ngClass]="
      {
        'navbar-offline': (online$ | async) === false,
        'navbar-warning': (showWarningUpdate$ | async) === true
      }"
     class="navbar navbar-expand-lg bg-gray-100 fixed-top">
  <div class="container-fluid p-3 p-lg-4 d-flex justify-content-between align-items-center">

    <div class="d-flex">
      <div class="logo">
        <a class="navbar-brand icon-link"
           [routerLink]="[locale]">
          <svg class="d-inline-block align-text-top menu-icon">
            <use xlink:href="./assets/images/assets.svg#digital-me"></use>
          </svg>
          <span i18n="@@app.title">digi.me</span>
        </a>
      </div>

      <app-header class="d-none d-lg-block"
                  [text]="pageHeader$ | async"></app-header>
    </div>

    <div class="d-flex gap-2">
      <app-log-in-button-container
                                   *ngIf="(isAuthenticated$ | async) === false && (mustCreateAccount$ | async) === false"></app-log-in-button-container>

      <app-add-drop-down-menu-container *ngIf="(isAuthenticated$ | async) === true"></app-add-drop-down-menu-container>
      <app-help-drop-down-menu></app-help-drop-down-menu>
    </div>
  </div>
</nav>
<div class="spacer-top"></div>