import { Inject, Injectable, InjectionToken } from '@angular/core';
import { FileSaver, MobileFile, WebFile } from '@globals';

export const FILE_SAVER_TOKEN = new InjectionToken<FileSaver>('FileSaver');

@Injectable({
  providedIn: 'root',
})
export class FileSaverService {
  private fileSaver: FileSaver;

  constructor(@Inject(FILE_SAVER_TOKEN) fileSaver: FileSaver) {
    this.fileSaver = fileSaver;
  }

  async save(report: MobileFile | WebFile): Promise<void> {
    await this.fileSaver.save(report);
  }
}
