import { CodeSystemFlattened } from '@hl7fhir/codesystems/models';
export const v3CodeSystemTimingEvent: CodeSystemFlattened = {
  id: 'v3-TimingEvent',
  codes: {
    AC: {
      display: 'AC',
    },
    ACD: {
      display: 'ACD',
    },
    ACM: {
      display: 'ACM',
    },
    ACV: {
      display: 'ACV',
    },
    C: {
      display: 'C',
    },
    HS: {
      display: 'HS',
    },
    IC: {
      display: 'IC',
    },
    ICD: {
      display: 'ICD',
    },
    ICM: {
      display: 'ICM',
    },
    ICV: {
      display: 'ICV',
    },
    PC: {
      display: 'PC',
    },
    PCD: {
      display: 'PCD',
    },
    PCM: {
      display: 'PCM',
    },
    PCV: {
      display: 'PCV',
    },
    WAKE: {
      display: 'WAKE',
    },
    CD: {
      display: 'CD',
    },
    CM: {
      display: 'CM',
    },
    CV: {
      display: 'CV',
    },
  },
};
