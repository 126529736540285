import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
  ModalStepperComponent,
} from '@layout';
import { MenuItem } from '@navigation/menu';
import { SelfMeasurementsMenuComponent } from './self-measurements-menu.component';
import { SelfMeasurementsModalBloodGlucoseComponent } from './self-measurements-modal-blood-glucose.component';
import { SelfMeasurementsModalBloodOxygenComponent } from './self-measurements-modal-blood-oxygen.component';
import { SelfMeasurementsModalBloodPressureComponent } from './self-measurements-modal-blood-pressure.component';
import { SelfMeasurementsModalHeartRateComponent } from './self-measurements-modal-heartrate.component';
import { SelfMeasurementsModalHeightComponent } from './self-measurements-modal-height.component';
import { SelfMeasurementsModalRespirationRateComponent } from './self-measurements-modal-respiration-rate.component';
import { SelfMeasurementsModalTemperatureComponent } from './self-measurements-modal-temperature.component';
import { SelfMeasurementsModalWeightComponent } from './self-measurements-modal-weight.component';
import { ISelfMeasurementsModal } from './self-measurements-modal.interface';
import { SelfMeasurementsModalService } from './services/self-measurements-modal.service';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal',
  imports: [
    CommonModule,
    ModalStepperComponent,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    SelfMeasurementsMenuComponent,
    ButtonComponent,
    HeaderComponent,
  ],
  templateUrl: './self-measurements-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalComponent implements ISelfMeasurementsModal {
  @ViewChild(ModalStepperComponent) public stepper!: ModalStepperComponent;
  measurementComponent: any;

  private readonly cdr = inject(ChangeDetectorRef);
  private readonly service = inject(SelfMeasurementsModalService);
  constructor() {
    this.service.setModal(this);
  }

  open(measurementType: string | undefined) {
    this.stepper.activeIndex = 0;
    if (measurementType) {
      this.selected({ icon: measurementType });
    }
    this.stepper.open();
  }

  selected($event: MenuItem) {
    switch ($event.icon) {
      case 'body-weights':
        this.measurementComponent = SelfMeasurementsModalWeightComponent;
        break;
      case 'body-heights':
        this.measurementComponent = SelfMeasurementsModalHeightComponent;
        break;
      case 'body-temperatures':
        this.measurementComponent = SelfMeasurementsModalTemperatureComponent;
        break;
      case 'heart-and-pulse-rates':
        this.measurementComponent = SelfMeasurementsModalHeartRateComponent;
        break;
      case 'glucoses':
        this.measurementComponent = SelfMeasurementsModalBloodGlucoseComponent;
        break;
      case 'blood-pressure':
        this.measurementComponent = SelfMeasurementsModalBloodPressureComponent;
        break;
      case 'respiration':
        this.measurementComponent = SelfMeasurementsModalRespirationRateComponent;
        break;
      case 'o2-saturations':
        this.measurementComponent = SelfMeasurementsModalBloodOxygenComponent;
        break;
    }
    this.stepper.next();
    this.cdr.detectChanges();
  }

  close() {
    this.stepper.close('cancel');
  }
}
