import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const TEMPERATUURTYPE_CODELIJST: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.6.1--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '307047009': {
        'nl-NL': 'Rectale temperatuur',
        display: 'Rectal temperature',
      },
      '415945006': {
        'nl-NL': 'Orale temperatuur (onder de tong)',
        display: 'Oral temperature',
      },
      '415882003': {
        'nl-NL': 'Axillaire temperatuur (onder de oksel)',
        display: 'Axillary temperature',
      },
      '415929009': {
        'nl-NL': 'Inguinale temperatuur (via de lies)',
        display: 'Groin temperature',
      },
      '415974002': {
        'nl-NL': 'Tympanische temperatuur',
        display: 'Tympanic temperature',
      },
      '415922000': {
        'nl-NL': 'Temporale temperatuur',
        display: 'Forehead temperature',
      },
      '364246006': {
        'nl-NL': 'Vaginale temperatuur',
        display: 'Temperature of vagina',
      },
      '698832009': {
        'nl-NL': 'Blaastemperatuur',
        display: 'Bladder temperature',
      },
      '276885007': {
        'nl-NL': 'Kern temperatuur (invasief gemeten)',
        display: 'Core body temperature',
      },
    },
  },
};
