import { formatLocaleDate } from '@globals';
import { Extension, NutritionOrder, Reference } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel } from '@hl7fhir/data-types';
import { ExtensionPipe } from '@hl7fhir/extensibility';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { NutritionOrderStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { SelectPipe } from '@shared';
import * as r3 from 'fhir/r3';
import { NutritionOrderEnteralFormulaViewModel } from './nutrition-order-enteral-formula.viewmodel';
import { NutritionOrderOralDietViewModel } from './nutrition-order-oral-diet.viewmodel';
import { NutritionOrderSupplementViewModel } from './nutrition-order-supplement.viewmodel';

export class NutritionOrderViewModel extends DomainResourceViewModel<NutritionOrder> {
  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map((identifier) => new IdentifierViewModel(identifier, this.fhirVersion));
  }

  get status(): string | undefined {
    return new NutritionOrderStatusPipe().transform(this.resource?.status);
  }

  get patient(): string | undefined {
    const nutritionOrderR = this.resource as r3.NutritionOrder;
    return getReference(nutritionOrderR?.patient);
  }

  get dateTime(): string | undefined {
    return formatLocaleDate(this.resource?.dateTime, 'long');
  }

  get sortDate(): string | undefined {
    return this.resource?.dateTime;
  }

  get allergyIntolerance(): string | undefined {
    return getReferences(this.resource?.allergyIntolerance);
  }

  get commentExtension(): string | undefined {
    if (this.resource?.extension) {
      const extension: Extension | undefined = new ExtensionPipe().transform(
        this.resource.extension,
        StructureDefinition.Nictiz.NUTRITION_ADVICE.explanation
      );

      if (extension) {
        const valueString: string | undefined = new SelectPipe().transform(extension, 'valueString');
        return valueString;
      }
    }

    return undefined;
  }

  get orderer(): string | undefined {
    return getReference(this.resource?.orderer);
  }

  get ordererExtension(): string | undefined {
    if (this.resource?.extension) {
      const extension: Extension | undefined = new ExtensionPipe().transform(
        this.resource.extension,
        StructureDefinition.Nictiz.PRACTITIONER_ROLE.reference
      );

      if (extension) {
        const valueReference: Reference | undefined = new SelectPipe().transform(extension, 'valueReference');
        return getReference(valueReference);
      }
    }

    return undefined;
  }

  get encounter(): string | undefined {
    return getReference(this.resource?.encounter);
  }

  get excludeFoodModifier(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.excludeFoodModifier);
  }

  get foodPreferenceModifier(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.foodPreferenceModifier);
  }

  get oralDietType(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.oralDiet?.type);
  }

  get oralDietFluidConsistencyType(): string | undefined {
    return new CodeableConceptPipe().transform(this.resource?.oralDiet?.fluidConsistencyType);
  }

  get oralDiet(): NutritionOrderOralDietViewModel | undefined {
    return this.resource?.oralDiet
      ? new NutritionOrderOralDietViewModel(this.resource.oralDiet, this.fhirVersion)
      : undefined;
  }

  get supplement(): NutritionOrderSupplementViewModel[] | undefined {
    return this.resource?.supplement?.map(
      (supplement) => new NutritionOrderSupplementViewModel(supplement, this.fhirVersion)
    );
  }

  get enteralFormula(): NutritionOrderEnteralFormulaViewModel | undefined {
    return this.resource?.enteralFormula
      ? new NutritionOrderEnteralFormulaViewModel(this.resource.enteralFormula, this.fhirVersion)
      : undefined;
  }
}
