import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const MEETMETHODE_CODELIJST: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.4.1--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '22762002': {
        'nl-NL': 'Niet-invasief',
        display: 'Non-invasive',
      },
      '10179008': {
        'nl-NL': 'Invasief',
        display: 'Invasive',
      },
    },
  },
};
