import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { configId } from '@environments/environment';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, take } from 'rxjs/operators';

export const isAuthenticated = () => {
  const oidcSecurityService = inject(OidcSecurityService);
  const router = inject(Router);

  // Using this implementation instead of isAuthenticated(configId) because that was returning true falsly
  return oidcSecurityService.isAuthenticated$.pipe(
    take(1),
    map((result) => {
      const isAuthenticated = result.allConfigsAuthenticated.some((x) => x.configId === configId && x.isAuthenticated);
      if (!isAuthenticated) {
        router.navigate([`${$localize.locale}`, 'onboard-or-log-in']);

        return false;
      }
      return true;
    })
  );
};
