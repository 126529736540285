import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const HOUDING_CODELIJST: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.4.5--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '10904000': {
        'nl-NL': 'Staande positie',
        display: 'Orthostatic body position',
      },
      '102538003': {
        'nl-NL': 'Liggende positie',
        display: 'Recumbent body position',
      },
      '33586001': {
        'nl-NL': 'Zittende positie',
        display: 'Sitting position',
      },
      '272587006': {
        'nl-NL': 'Achteroverleunende positie',
        display: 'Position with tilt',
      },
      '34106002': {
        'nl-NL': 'Trendelenburgligging',
        display: 'Trendelenburg position',
      },
    },
  },
};
