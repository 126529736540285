import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const RitmeCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.5.1--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '5467003': {
        'nl-NL': 'Normaal ademhalingsritme',
        display: 'Normal respiratory rhythm',
      },
      '85617008': {
        'nl-NL': 'Abnormaal ademhalingsritme',
        display: 'Abnormal respiratory rhythm',
      },
    },
  },
};
