import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const KLEDING_CODELIJST: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.1.1--20171231000000',
  systems: {
    'urn:oid:2.16.840.1.113883.2.4.3.11.60.40.4.8.1': {
      UNDRESSED: {
        'nl-NL': 'Zonder kleding.',
        display: 'Undressed',
      },
      MINIMAL: {
        'nl-NL': 'Lichte kleding/ondergoed [Kleding die niet significant het gewicht beïnvloedt.]',
        display: 'Minimal dressed',
      },
      FULL: {
        'nl-NL':
          'Volledig gekleed, inclusief schoenen [Kleren die een significante bijdrage hebben aan het gewicht, inclusief schoenen.]',
        display: 'Fully dressed',
      },
      DIAPER: {
        'nl-NL': 'Luier [Individu draagt alleen een luier - zou significant aan het gewicht kunnen bijdragen.]',
        display: 'Diaper',
      },
    },
  },
};
