import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';
import { MenuItem } from '../menu';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  standalone: true,
  imports: [SharedModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuComponent {
  @Input({ required: true }) menu: MenuItem[] | undefined | null;

  constructor(private readonly router: Router) {}

  select(item: MenuItem): void {
    this.router.navigate([item.path]);
  }
}
