import { Capacitor } from '@capacitor/core';
import { Directory, Encoding } from '@capacitor/filesystem';
import { MobileFile, WebFile } from '../models';
import { MobileFileSaver, WebFileSaver } from './file.utils';

/**
 * Downloads a JSON object as a file.
 *
 * @param data - The JSON object to download.
 * @param filename - The name of the downloaded file.
 */
export function downloadJSON(data: any, filename: string): void {
  const jsonStr = JSON.stringify(data);

  const file = {
    data: jsonStr,
    name: filename,
  };

  if (Capacitor.isNativePlatform()) {
    const specificNativeFile: MobileFile = {
      ...file,
      path: file.name,
      directory: Directory.Cache,
      encoding: Encoding.UTF8,
    };
    const saver = new MobileFileSaver();
    saver.save(specificNativeFile);
  } else {
    const specificWebFile: WebFile = {
      ...file,
      type: 'application/json',
    };
    const saver = new WebFileSaver();
    saver.save(specificWebFile);
  }
}
