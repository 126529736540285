export interface MobileVersionDetails {
  // true, if the version is unsupported
  force: {
    show: boolean;
  };
  // Only false if on latest version?
  notification: {
    show: boolean;
  };
  // If the version is lower or equal to the version we are dropping soon
  warning: {
    show: boolean;
  };
  updateUrl?: string;
}
