<div class="px-4 pt-2 custom-period-date-selector-container">
  <div class="my-2 fw-bold">{{ header }}</div>

  <div class="d-lg-flex flex-lg-row">

    <mat-form-field class="w-100 me-2">
      <input data-cy="date-picker-start"
             matInput
             readonly
             [matDatepicker]="datePickerStart"
             [(ngModel)]="periodStart"
             [max]="maxStartDate"
             (click)="datePickerStart.open()"
             (ngModelChange)="onDateChange()"
             placeholder="Choose a from date"
             i18n-placeholder="@@app.InputElement.chooseFromDate" />
      <mat-datepicker-toggle matSuffix
                             [for]="datePickerStart"></mat-datepicker-toggle>
      <mat-datepicker #datePickerStart></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="w-100">
      <input data-cy="date-picker-end"
             matInput
             readonly
             [matDatepicker]="datePickerEnd"
             [(ngModel)]="periodEnd"
             (ngModelChange)="onDateChange()"
             [min]="minEndDate"
             [max]="currentDate"
             (click)="datePickerEnd.open()"
             placeholder="Choose a to date"
             i18n-placeholder="@@app.InputElement.chooseToDate" />
      <mat-datepicker-toggle matSuffix
                             [for]="datePickerEnd"></mat-datepicker-toggle>
      <mat-datepicker #datePickerEnd></mat-datepicker>
    </mat-form-field>
  </div>