import * as r3 from 'fhir/r3';

export const BLOOD_OXYGEN_TEMPLATE: r3.Observation = {
  resourceType: 'Observation',

  id: '$UUID_CREATED_FOR_THE_RESOURCE',
  identifier: [
    {
      system: 'urn:oid:$OID_FOR_DM_PRODUCT',
      value: 'urn:uuid:$UUID_CREATED_FOR_THE_RESOURCE',
    },
  ],
  effectiveDateTime: '$DATETIME_COLLECTED_FROM_USER',
  category: [
    {
      coding: [
        {
          display: 'Vital Signs',
          system: 'http://terminology.hl7.org/CodeSystem/observation-category',
          code: 'vital-signs',
        },
      ],
    },
  ],
  subject: {
    display: 'Patient',
    reference: 'urn:uuid:$UUID_FOR_PATIENT',
  },
  performer: [
    {
      display: 'Patient',
      reference: 'urn:uuid:$UUID_FOR_PATIENT',
    },
  ],
  status: 'final',
  meta: {
    profile: [
      'http://hl7.org/fhir/3.0/StructureDefinition/Observation',
      'http://nictiz.nl/fhir/StructureDefinition/zib-OxygenSaturation',
    ],
  },

  valueQuantity: {
    value: parseFloat('$VALUE'),
    unit: '%O2',
    system: 'http://unitsofmeasure.org',
    code: '%',
  },

  code: {
    coding: [
      {
        system: 'http://loinc.org',
        code: '2708-6',
        display: 'Oxygen saturation in Arterial blood',
      },
      {
        system: 'http://loinc.org',
        code: '59408-5',
        display: 'Oxygen saturation in Arterial blood by Pulse oximetry',
      },
    ],
  },
  component: [
    {
      code: {
        coding: [
          {
            code: '74206-4',
            system: 'http://loinc.org',
            display: 'Oxygen therapy was given [NTDS]',
          },
        ],
      },
      valueCodeableConcept: {
        coding: [
          {
            code: '$CODE_YES_NO',
            system: 'http://hl7.org/fhir/v2/0136',
            display: '$DISPLAY_YES_NO',
          },
        ],
      },
    },
  ],
};
