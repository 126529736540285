<div ngbDropdown
     placement="end-bottom"
     #drop="ngbDropdown"
     class="d-inline-block">
  <app-button type="secondary"
              icon="menu"
              class="caret-off"
              data-cy="help-menu-button"
              ngbDropdownToggle>
  </app-button>
  <div ngbDropdownMenu
       class="dropdown-menu-end">
    <div class="p-3">
      <div class="d-grid gap-2">
        <!-- Help Button -->
        <app-help (selected)="close()"></app-help>

        <!-- Report A Bug -->
        <app-button type="secondary"
                    use="menu"
                    icon="report"
                    i18n-label="@@app.ReportBug"
                    label="Report A Bug"
                    (clicked)="reportButtonClicked()"></app-button>

        @if(isAuthenticated$ | async) {

        <hr class="w-75 delimeter">
        <app-settings (selected)="settings()"></app-settings>

        <app-button type="secondary"
                    use="menu"
                    [icon]="'logout'"
                    label="Log out"
                    i18n-label="@@app.hamburgerMenu.logOut"
                    (clicked)="logout(); drop.close()"></app-button>

        } @else {
        <hr class="w-75 delimeter">
        <app-button type="secondary"
                    use="menu"
                    [icon]="locale === 'en-US' ? 'radio_button_checked' : 'radio_button_unchecked'"
                    label="English"
                    i18n-label="@@app.language.english"
                    (clicked)="changeLanguage('en-US'); drop.close()"></app-button>

        <app-button type="secondary"
                    use="menu"
                    [icon]="locale === 'nl' ? 'radio_button_checked' : 'radio_button_unchecked'"
                    label="Dutch"
                    i18n-label="@@app.language.dutch"
                    (clicked)="changeLanguage('nl'); drop.close()"></app-button>
        }
      </div>
    </div>
  </div>
</div>