import { formatLocaleDate } from '@globals';
import {
  Address,
  CodeableConcept,
  Coding,
  ContactPoint,
  Extension,
  HumanName,
  Identifier,
  Patient,
  Reference,
} from '@hl7fhir';
import {
  AddressViewModel,
  CodeableConceptPipe,
  CodingsPipe,
  ContactPointViewModel,
  FullNamePipe,
  HumanNameViewModel,
  IdentifierViewModel,
  getEmail,
  getPhone,
} from '@hl7fhir/data-types';
import { ExtensionPipe, ExtensionsPipe } from '@hl7fhir/extensibility';
import { getReference } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { AdministrativeGenderPipe, MaritalStatusPipe } from '@hl7fhir/value-sets';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import { GeslachtCodelijst, JuridischeStatusCodelijst, LevensovertuigingCodelijst } from '@hl7nl-fhir/value-sets';
import { SelectPipe, SelectsPipe } from '@shared';

export class PatientViewModel extends DomainResourceViewModel<Patient> {
  get name(): string | undefined {
    return new FullNamePipe().transform(this.resource?.name);
  }

  get birthDate(): string | undefined {
    return formatLocaleDate(this.resource?.birthDate, 'long');
  }

  get sortDate(): string | undefined {
    return this.resource?.birthDate;
  }

  get gender(): string | undefined {
    if (this.resource?._gender?.extension) {
      const extension: Extension | undefined = new ExtensionPipe().transform(
        this.resource?._gender?.extension,
        StructureDefinition.Nictiz.CODE.specification
      );

      if (extension) {
        const valueCodeableConcept: CodeableConcept | undefined = new SelectPipe().transform(
          extension,
          'valueCodeableConcept'
        );
        return new CodeableConceptPipe().transform(valueCodeableConcept, [GeslachtCodelijst]);
      }
    }

    return new AdministrativeGenderPipe().transform(this.resource?.gender);
  }

  get maritalStatus(): string | undefined {
    return new MaritalStatusPipe().transform(this.resource?.maritalStatus);
  }

  get multipleBirthBoolean(): boolean | undefined {
    return this.resource?.multipleBirthBoolean;
  }

  get multipleBirthInteger(): number | undefined {
    return this.resource?.multipleBirthInteger;
  }

  get deceasedBoolean(): boolean | undefined {
    return this.resource?.deceasedBoolean;
  }

  get deceasedDateTime(): string | undefined {
    return formatLocaleDate(this.resource?.deceasedDateTime, 'long');
  }

  get lifeStance(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.PATIENT.lifeStance
    );
    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectPipe().transform(
      extension,
      'valueCodeableConcept'
    );

    return new CodeableConceptPipe().transform(valueCodeableConcepts, [LevensovertuigingCodelijst]);
  }

  get legalStatus(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.PATIENT.legalStatus
    );
    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectPipe().transform(
      extension,
      'valueCodeableConcept'
    );

    return new CodeableConceptPipe().transform(valueCodeableConcepts, [JuridischeStatusCodelijst]);
  }

  get nationality(): string | undefined {
    const extensions: Extension[] | undefined = new ExtensionsPipe().transform(this.resource?.extension, 'code');
    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectsPipe().transform(
      extensions,
      'valueCodeableConcept'
    );

    return new CodeableConceptPipe().transform(valueCodeableConcepts);
  }

  get preferredPharmacy(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.PATIENT.preferredPharmacy
    );
    const valueReference: Reference | undefined = new SelectPipe().transform(extension, 'valueReference');

    return getReference(valueReference);
  }

  get proficiency(): string | undefined {
    // ! TODO Check if it works correctly
    const communicationExtensions = this.resource?.communication
      ?.map((communication) => communication.extension)
      .flat()
      .filter((extension): extension is Extension => !!extension);

    const proficiencyExtensions: Extension[] | undefined = new ExtensionsPipe().transform(
      communicationExtensions,
      StructureDefinition.Nictiz.PATIENT.proficiency
    );
    const proficiencyTypeExtensions: Extension[] | undefined = new ExtensionsPipe().transform(
      proficiencyExtensions,
      'type'
    );
    const proficiencyLevelExtensions: Extension[] | undefined = new ExtensionsPipe().transform(
      proficiencyExtensions,
      'level'
    );

    let valueCodings: Coding[] | undefined = undefined;

    if (proficiencyTypeExtensions) {
      valueCodings = new SelectsPipe().transform(proficiencyTypeExtensions, 'valueCoding');
    } else if (proficiencyLevelExtensions) {
      valueCodings = new SelectsPipe().transform(proficiencyLevelExtensions, 'valueCoding');
    }

    return new CodingsPipe().transform(valueCodings);
  }

  get phone(): string | undefined {
    return getPhone(this.resource?.telecom);
  }

  get email(): string | undefined {
    return getEmail(this.resource?.telecom);
  }

  get identifiers(): IdentifierViewModel[] | undefined {
    return this.resource?.identifier?.map(
      (identifier: Identifier) => new IdentifierViewModel(identifier, this.fhirVersion)
    );
  }

  get humanNames(): HumanNameViewModel[] | undefined {
    return this.resource?.name?.map((humanName: HumanName) => new HumanNameViewModel(humanName, this.fhirVersion));
  }

  get addresses(): AddressViewModel[] | undefined {
    return this.resource?.address?.map((address: Address) => new AddressViewModel(address, this.fhirVersion));
  }

  get contactPoints(): ContactPointViewModel[] | undefined {
    return this.resource?.telecom?.map(
      (contactPoint: ContactPoint) => new ContactPointViewModel(contactPoint, this.fhirVersion)
    );
  }
}
