import { ValueSetFlattened } from '@hl7fhir/value-sets';
export const DiepteCodelijst: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.5.2--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '301284009': {
        'nl-NL': 'Normale ademhalingsdiepte',
        display: 'Normal depth of breathing',
      },
      '386616007': {
        'nl-NL': 'Oppervlakkige ademhaling',
        display: 'Shallow breathing',
      },
      '289123006': {
        'nl-NL': 'Diepe ademhaling',
        display: 'Deep breathing',
      },
      '248587009': {
        'nl-NL': 'Ademhalingsdiepte varieert',
        display: 'Depth of respiration varies',
      },
    },
  },
};
