import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const HARTSLAGREGELMATIGHEID_CODELIJST: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.3.1--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '271636001': {
        'nl-NL': 'Hartslag regelmatig',
        display: 'Heart regular',
      },
      '248650006': {
        'nl-NL': 'Hartslag onregelmatig',
        display: 'Heart irregular',
      },
    },
  },
};
