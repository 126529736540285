import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { nameof } from '@globals';
import { Coding } from '@hl7fhir';
import { ButtonComponent } from '@layout';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-blood-glucose-entry',
  templateUrl: './blood-glucose-entry.component.html',
  imports: [SharedModule, ButtonComponent],
})
export class BloodGlucoseEntryComponent {
  public static glucoseCodelijstCodings: Coding[] = [
    { system: 'http://loinc.org', code: '14770-2', display: 'Glucose^post CFst' },
    { system: 'http://loinc.org', code: '14743-9', display: 'Glucose' },
    { system: 'http://loinc.org', code: '14760-3', display: 'Glucose^2H post meal' },
  ];

  public static timingEventCodelijstCodings: Coding[] = [
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'AC', display: 'AC' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ACD', display: 'ACD' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ACM', display: 'ACM' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ACV', display: 'ACV' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'C', display: 'C' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'HS', display: 'HS' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'IC', display: 'IC' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ICD', display: 'ICD' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ICM', display: 'ICM' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'ICV', display: 'ICV' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'PC', display: 'PC' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'PCD', display: 'PCD' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'PCM', display: 'PCM' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'PCV', display: 'PCV' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'WAKE', display: 'WAKE' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'CD', display: 'CD' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'CM', display: 'CM' },
    { system: 'http://hl7.org/fhir/v3/TimingEvent', code: 'CV', display: 'CV' },
  ];

  @Input({ required: true }) form!: FormGroup;

  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  readonly translatedKledingCodelijst: Coding[];
  readonly translatedTimingList: Coding[];

  constructor() {
    this.translatedKledingCodelijst = BloodGlucoseEntryComponent.glucoseCodelijstCodings;
    this.translatedTimingList = BloodGlucoseEntryComponent.timingEventCodelijstCodings;
  }

  get maxDay(): string {
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOString = new Date(Date.now() - tzoffset).toISOString();
    const dateString = localISOString.substring(0, 16);

    return dateString;
  }

  get dateControl() {
    return this.form.get(nameof<BloodGlucoseEntryComponent>('dateControl'));
  }

  get bloodGlucoseControl() {
    return this.form.get(nameof<BloodGlucoseEntryComponent>('bloodGlucoseControl'));
  }

  get codeControl() {
    return this.form.get(nameof<BloodGlucoseEntryComponent>('codeControl'));
  }

  get timingControl() {
    return this.form.get(nameof<BloodGlucoseEntryComponent>('timingControl'));
  }

  /**
   * Limits the input value of a target element to a maximum available number and a specific decimal pattern.
   * @param target - The target element that triggered the event.
   */
  limitAndFormatDecimalInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const patternThreeDecimal = /^\d+(\.\d{3,})$/;
    const maxNumber = 999;
    const minNumber = 0;
    const value = parseFloat(input.value);

    if (value > maxNumber) {
      input.value = maxNumber.toString();
      this.bloodGlucoseControl!.setValue(input.value);
    } else if (value < minNumber) {
      input.value = minNumber.toString();
      this.bloodGlucoseControl!.setValue(input.value);
    } else if (patternThreeDecimal.test(input.value)) {
      input.value = value.toFixed(2);
      this.bloodGlucoseControl!.setValue(input.value);
    }
  }
}
