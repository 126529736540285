import { ValueSetFlattened } from '@hl7fhir/value-sets';

export const HARTSLAGMEETMETHODE_CODELIJST: ValueSetFlattened = {
  id: '2.16.840.1.113883.2.4.3.11.60.40.2.12.3.2--20171231000000',
  systems: {
    'http://snomed.info/sct': {
      '113011001': {
        'nl-NL': 'Palpatie',
        display: 'Palpation',
      },
      '37931006': {
        'nl-NL': 'Auscultatie',
        display: 'Auscultation',
      },
      '88140007': {
        'nl-NL': 'Cardiale monitoring',
        display: 'Cardiac monitoring',
      },
      '46825001': {
        'nl-NL': 'Electrocardiografie',
        display: 'ECG',
      },
    },
  },
};
