import { createSelector } from '@ngrx/store';
import { DigiMeState } from '../digi-me.reducer';
import { selectDigiMeState } from './digi-me.selectors';

export const selectShowForceUpdateModal = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.forceUpdate.force.show
);

export const selectShowWarningUpdate = createSelector(
  selectDigiMeState,
  (state: DigiMeState) => state.forceUpdate.warning.show
);

export const selectUpdateUrl = createSelector(selectDigiMeState, (state: DigiMeState) => state.forceUpdate.updateUrl);
