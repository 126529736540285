import { Directory, Encoding } from '@capacitor/filesystem';

export interface FileSaver {
  save(file: MobileFile | WebFile): Promise<void>;
}

export interface MobileFile {
  data: any;
  path: string;
  directory: Directory;
  encoding?: Encoding;
}

export interface WebFile {
  data: any;
  type: string;
  name: string;
}
