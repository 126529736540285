import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '@layout';
import { getMenuConfig, MenuItem } from '@navigation/menu';
import { SharedModule } from '@shared';
import { SideMenuComponent } from '../side-menu/side-menu.component';

@Component({
  standalone: true,
  selector: 'app-middle-menu',
  templateUrl: './middle-menu.component.html',
  imports: [SharedModule, ButtonComponent, SideMenuComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiddleMenuComponent {
  @Input({ required: true }) selectedItem: MenuItem | undefined | null;
  @Input() displayName: string = ' ';
  @Input() hasSources: boolean = true;
  @Input() isLoadingAuthorizeUrl: boolean = true;

  @Output() selected = new EventEmitter<MenuItem>();
  @Output() onboard = new EventEmitter();

  readonly menuConfig: MenuItem[] = getMenuConfig().menuItems;

  select(item: MenuItem): void {
    this.selected.emit(item);
  }
}
