import { AbstractSecurityStorage } from 'angular-auth-oidc-client';

export class SessionStorageService implements AbstractSecurityStorage {
  read(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  write(key: string, value: string): void {
    sessionStorage.setItem(key, value);
  }

  remove(key: string): void {
    sessionStorage.removeItem(key);
  }

  clear(): void {
    sessionStorage.clear();
  }
}
