import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { ButtonComponent } from '@layout';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SelfMeasurementsModalComponent } from '@self-measurements';
import { SharedModule } from '@shared';
import { MenuItem } from '../../menu';

@Component({
  standalone: true,
  selector: 'app-add-drop-down-menu',
  templateUrl: './add-drop-down-menu.component.html',
  imports: [NgbDropdownModule, ButtonComponent, SharedModule, SelfMeasurementsModalComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDropDownMenuComponent {
  @ViewChild('drop', { static: true }) dropdown: NgbDropdown | undefined;

  @Input({ required: false }) menuItem: MenuItem | null | undefined;

  @Output() linkSource = new EventEmitter();
  @Output() addMeasurement = new EventEmitter<string | undefined>();

  get menuItemEnabled(): boolean {
    switch (this.menuItem?.icon) {
      case 'blood-pressure':
        return environment.features.selfMeasurements.bloodPressure;
      case 'body-heights':
        return environment.features.selfMeasurements.bodyHeight;
      case 'body-temperatures':
        return environment.features.selfMeasurements.bodyTemperature;
      case 'body-weights':
        return environment.features.selfMeasurements.bodyWeight;
      case 'glucoses':
        return environment.features.selfMeasurements.glucose;
      case 'heart-and-pulse-rates':
        return environment.features.selfMeasurements.heartAndPulseRates;
      case 'o2-saturations':
        return environment.features.selfMeasurements.o2saturations;
      case 'respiration':
        return environment.features.selfMeasurements.respiratory;
      default:
        return false;
    }
  }
}
