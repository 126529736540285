import { formatLocaleDate } from '@globals';
import { Composition } from '@hl7fhir';
import { CodeableConceptPipe, IdentifierViewModel } from '@hl7fhir/data-types';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import { CompositionAttesterViewModel } from './composition-attester.viewmodel';
import { CompositionEventViewModel } from './composition-event.viewmodel';
import { CompositionRelatesToViewModel } from './composition-relates-to.viewmodel';
import { CompositionSectionViewModel } from './composition-section.viewmodel';

export class CompositionViewModel extends DomainResourceViewModel<Composition> {
  get title(): string | undefined {
    return this.resource?.title;
  }

  get type(): string | undefined {
    return this.resource?.type && new CodeableConceptPipe().transform(this.resource.type);
  }

  get date(): string | undefined {
    return this.resource?.date && formatLocaleDate(this.resource.date, 'long');
  }

  get sortDate(): string | undefined {
    return this.resource?.date;
  }

  get status(): string | undefined {
    return this.resource?.status;
  }

  get author(): string | undefined {
    return this.resource?.author && getReferences(this.resource.author);
  }

  //R3 only
  get class(): string | undefined {
    const composition = this.resource as r3.Composition;
    return composition?.class && new CodeableConceptPipe().transform(composition.class);
  }

  //R3, R4, R4b only
  get confidentiality(): string | undefined {
    const composition = this.resource as r3.Composition | r4.Composition | r4b.Composition;
    return composition?.confidentiality;
  }

  get custodian(): string | undefined {
    return this.resource?.custodian && getReference(this.resource.custodian);
  }

  get encounter(): string | undefined {
    return this.resource?.encounter && getReference(this.resource.encounter);
  }

  //R3, R4, R4b only
  get identifier(): IdentifierViewModel | undefined {
    const composition = this.resource as r3.Composition | r4.Composition | r4b.Composition;
    return composition?.identifier && new IdentifierViewModel(composition.identifier, this.fhirVersion);
  }

  //R3, R4, R4b only
  get subject(): string | undefined {
    const composition = this.resource as r3.Composition | r4.Composition | r4b.Composition;
    return composition?.subject && getReference(composition.subject);
  }

  get attester(): CompositionAttesterViewModel[] | undefined {
    return (
      this.resource?.attester &&
      this.resource.attester.map((attester) => new CompositionAttesterViewModel(attester, this.fhirVersion))
    );
  }

  get event(): CompositionEventViewModel[] | undefined {
    return (
      this.resource?.event && this.resource.event.map((event) => new CompositionEventViewModel(event, this.fhirVersion))
    );
  }

  get relatesTo(): CompositionRelatesToViewModel[] | undefined {
    const composition = this.resource as r3.Composition | r4.Composition | r4b.Composition;
    return (
      composition?.relatesTo &&
      composition.relatesTo.map((relatesTo) => new CompositionRelatesToViewModel(relatesTo, this.fhirVersion))
    );
  }

  get section(): CompositionSectionViewModel[] | undefined {
    return (
      this.resource?.section &&
      this.resource.section.map((section) => new CompositionSectionViewModel(section, this.fhirVersion))
    );
  }

  get icpcCode(): string | undefined {
    return (
      this.section &&
      this.section
        .map((section) => section.icpcCode)
        .filter((icpcCode) => icpcCode != null)
        .join(', ')
    );
  }

  get icpcDescription(): string | undefined {
    return (
      this.section &&
      this.section
        .map((section) => section.icpcDescription)
        .filter((icpcDescription) => icpcDescription != null)
        .join(', ')
    );
  }
}
