import { Component, EventEmitter, Output } from '@angular/core';
import { FooterComponent } from '@core';
import { ButtonComponent, MedMijTrustedComponent, WdxFooterComponent } from '@layout';
import { NavigationModule } from '@navigation';
import { SessionTimeoutContainerComponent } from './session-timeout.container';

@Component({
  standalone: true,
  selector: 'app-onboard-or-log-in',
  templateUrl: 'onboard-or-log-in.component.html',
  styleUrl: 'onboard-or-log-in.component.scss',
  imports: [
    MedMijTrustedComponent,
    FooterComponent,
    SessionTimeoutContainerComponent,
    ButtonComponent,
    WdxFooterComponent,
    NavigationModule,
  ],
})
export class OnboardOrLogInComponent {
  @Output() login = new EventEmitter();
  @Output() onboard = new EventEmitter();

  digiMeHref = $localize.locale === 'nl' ? 'https://digi.me/nl/how' : 'https://digi.me/how';
}
