<div [class.d-grid]="use === 'block' || use === 'menu'">
  <button *ngIf="visible && !href"
          data-cy="button-normal"
          class="btn icon-link text-nowrap"
          [class.justify-content-center]="use === 'block'"
          (click)="onClicked()"
          [ngbTooltip]="tooltip"
          [triggers]="triggerTooltip"
          placement="top"
          [disabled]="disabled"
          [tooltipClass]="tooltipClass"
          [class.btn-primary]="type==='primary'"
          [class.btn-secondary]="type==='secondary'"
          [class.btn-destructive]="type==='destructive'"
          [class.btn-navigation]="type==='navigation'"
          [class.btn-link]="type==='link'"
          [class.selected]="selected"
          [type]="btnType">
    <mat-icon *ngIf="icon && !working"
              [fontIcon]="icon"></mat-icon>
    <svg *ngIf="svg && !working"
         class="menu-icon">
      <use attr.xlink:href="./assets/images/assets.svg#{{svg}}"></use>
    </svg>
    <span *ngIf="working"
          class="spinner-border spinner-border-sm"
          aria-hidden="true"></span>
    <span *ngIf="label"
          [class.flex-fill]="rightIcon"
          class="text-start">{{ label }}</span>

    <svg *ngIf="rightIcon"
         style="width: 1.25rem; height: 1.25rem">
      <use xlink:href="./assets/images/assets.svg#forward-arrow"></use>
    </svg>
    <ng-content></ng-content>
  </button>

  <a *ngIf="visible && href"
     data-cy="button-href"
     class="btn icon-link text-nowrap"
     [class.justify-content-center]="use === 'block'"
     (click)="onClicked()"
     [class.btn-primary]="type === 'primary'"
     [class.btn-secondary]="type === 'secondary'"
     [class.btn-destructive]="type === 'destructive'"
     [class.btn-navigation]="type === 'navigation'"
     [class.selected]="selected"
     [attr.href]="href"
     [attr.target]="target"
     type="button">

    <span *ngIf="working"
          class="spinner-border spinner-border-sm"
          aria-hidden="true"></span>
    <mat-icon *ngIf="icon && !working"
              [fontIcon]="icon"></mat-icon>
    <svg *ngIf="svg && !working"
         class="menu-icon">
      <use attr.xlink:href="./assets/images/assets.svg#{{svg}}"></use>
    </svg>
    <span *ngIf="working"
          class="spinner-border spinner-border-sm"
          aria-hidden="true"></span>
    <span *ngIf="label">{{ label }}</span>
  </a>
</div>