import { Injectable } from '@angular/core';

export enum LocalStorageKeys {
  idleStart = 'idleStart',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  read(key: LocalStorageKeys): string | null {
    return localStorage.getItem(key);
  }

  write(key: LocalStorageKeys, value: string): void {
    localStorage.setItem(key, value);
  }

  remove(key: LocalStorageKeys): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }
}
