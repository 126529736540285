import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { loadTranslations } from '@angular/localize';
import messagesEnUS from '../locale/messages.en_US.json';
import messagesNl from '../locale/messages.nl.json';

export function loadOrUseFallbackTranslations(locale: string) {
  // Get translations from the compiled app
  switch (locale) {
    case 'en-US':
      loadTranslations(messagesEnUS.translations);
      break;
    case 'nl':
      registerLocaleData(localeNl);
      loadTranslations(messagesNl.translations);
      break;
    default:
      registerLocaleData(localeNl);
      loadTranslations(messagesNl.translations);
      break;
  }
}
