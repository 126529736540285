<form [formGroup]="form">
  <div class="d-flex flex-column mb-3">
    <label for="dateInput"
           class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.date">Date</span> *</label>
    <input type="datetime-local"
           class="form-control"
           id="dateInput"
           formControlName="dateControl"
           [max]="maxDay"
           [class.is-invalid]="dateControl?.invalid && dateControl?.touched"
           required />
  </div>

  <div class="d-flex">
    <div class="d-flex flex-column flex-fill mb-3 me-3">
      <label for="systolicControl"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.blood-pressure.systolic">Systolic
          (mmHg)</span> *</label>
      <input type="number"
             class="form-control"
             id="systolicControl"
             formControlName="systolicControl"
             min="0"
             max="999"
             step="1"
             (input)="limitAndFormatDecimalInput($event.target, systolicControl)"
             [class.is-invalid]="systolicControl?.invalid && systolicControl?.touched"
             required />
    </div>

    <div class="d-flex flex-column flex-fill mb-3 me-3">
      <label for="diastolicControl"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.blood-pressure.diastolic">Diastolic
          (mmHg)</span> *</label>
      <input type="number"
             class="form-control"
             id="diastolicControl"
             formControlName="diastolicControl"
             min="0"
             max="999"
             step="1"
             (input)="limitAndFormatDecimalInput($event.target, diastolicControl)"
             [class.is-invalid]="diastolicControl?.invalid && diastolicControl?.touched"
             required />
    </div>
  </div>

  <div class="d-flex flex-column border border-3 border-gray-300 rounded p-2">

    <div class="d-flex flex-column mb-3">
      <label for="positionControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.blood-pressure.position">Position</span>
        <span i18n="@@app.input.optional">(Optional)</span>
      </label>
      <select id="positionControl"
              class="form-control form-select"
              formControlName="positionControl">
        <option value=""
                selected><span i18n="@@app.input.select">- select -</span></option>
        <option *ngFor="let coding of translatedHoudingCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="cuffSizeControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.blood-pressure.cuff-size">Cuff size</span>
        <span i18n="@@app.input.optional">(Optional)</span>
      </label>
      <select id="cuffSizeControl"
              class="form-control form-select"
              formControlName="cuffSizeControl">
        <option value=""
                selected><span i18n="@@app.input.select">- select -</span></option>
        <option *ngFor="let coding of translatedManchetTypeCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="locationControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.blood-pressure.location">Location</span>
        <span i18n="@@app.input.optional">(Optional)</span>
      </label>
      <select id="locationControl"
              class="form-control form-select"
              formControlName="locationControl">
        <option value=""
                selected><span i18n="@@app.input.select">- select -</span></option>
        <option *ngFor="let coding of translatedMeetlocatieCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="methodControl"
             class="col-form-label fs-6 fw-bolder">
        <span i18n="@@app.input.blood-pressure.method">Method</span>
        <span i18n="@@app.input.optional">(Optional)</span>
      </label>
      <select id="methodControl"
              class="form-control form-select"
              formControlName="methodControl">
        <option value=""
                selected><span i18n="@@app.input.select">- select -</span></option>
        <option *ngFor="let coding of translatedMeetmethodeCodelijst"
                [value]="coding.code">{{ coding.display }}</option>
      </select>
    </div>

    <div class="d-flex flex-column mb-3">
      <label for="additionalCommentsInput"
             class="col-form-label fs-6 fw-bolder"><span i18n="@@app.input.additionalComment">Additional Comments</span>
        <span i18n="@@app.input.optional">(Optional)</span></label>

      <textarea type="text"
                class="form-control text-truncate textarea-scroll"
                formControlName="additionalCommentsControl"
                maxlength="300"
                pattern="^[\s\S]+$"
                id="additionalCommentsInput"
                rows="2"></textarea>
    </div>
  </div>
</form>