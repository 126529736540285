import { CommonModule } from '@angular/common';
import { Component, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Browser } from '@capacitor/browser';
import { Store } from '@ngrx/store';
import { selectShowWarningUpdate, selectUpdateUrl } from '@store/digi.me';
import { take } from 'rxjs';

@Component({
  selector: 'app-warning-update',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      *ngIf="(showForceUpdateModal$ | async) === true"
      class="warning-update">
      <div class="warning-content">
        <svg class="warning-icon">
          <use xlink:href="./assets/images/assets.svg#warning-update"></use>
        </svg>
        <span class="warning-text">
          <strong
            ><a
              i18n="@@app.update.warning.link"
              href="#"
              (click)="openAppStore()"
              class="update-link"
              >Update now</a
            >
            &nbsp;<span i18n="@@app.update.warning.text">to keep your data safe</span></strong
          >
        </span>
      </div>
    </div>
  `,
})
export class WarningUpdateComponent {
  readonly showForceUpdateModal$ = this.store.select(selectShowWarningUpdate);
  readonly updateUrl$ = this.store.select(selectUpdateUrl);

  constructor(
    private store: Store,
    private destroyRef: DestroyRef,
  ) {}

  openAppStore() {
    this.updateUrl$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe((url) => {
      Browser.open({ url });
    });
  }
}
