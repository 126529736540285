<div class="d-sm-block d-lg-none">
  @if(selectedItem) {
  <div class="d-flex align-items-center mb-3">
    <app-button target="_self"
                type="navigation"
                svg="back-arrow"
                (clicked)="select(selectedItem.parent!)"></app-button>

    <h2 class="m-0 ms-1 flex-fill">{{ selectedItem.header }}</h2>

    <svg class="header-menu-icon">
      <use attr.xlink:href="./assets/images/assets.svg#{{selectedItem.icon}}"></use>
    </svg>
  </div>

  <app-button *ngFor="let item of selectedItem.subMenu"
              use="menu"
              type="navigation"
              [rightIcon]="true"
              [svg]="item.icon"
              [label]="item.header"
              (clicked)="select(item)"></app-button>
  } @else {
  <div class="home-page ps-1">
    <h2 i18n="@@app.home.hello">Hello</h2>
    <h1 class="fw-bold">{{displayName}}&nbsp;</h1>
  </div>
  <div *ngIf="!this.hasSources"
       class="mb-3 h-100">
    <app-button type="primary"
                [svg]="'plus-white'"
                label="Add medical records"
                (clicked)="this.onboard.emit();"
                [working]="isLoadingAuthorizeUrl"
                i18n-label="@@app.helper.button.addFirstRecords"></app-button>
  </div>
  <ng-container *ngFor="let item of menuConfig">
    @if(item.placeholder) {

    <div class="d-flex justify-content-center">
      <hr class="w-75 delimeter">
    </div>

    } @else {

    <app-button use="menu"
                type="navigation"
                [svg]="item.icon"
                [rightIcon]="true"
                [label]="item.header"
                (clicked)="select(item)"></app-button>

    }
  </ng-container>
  }
</div>