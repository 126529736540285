export function limitAndFormatDecimal(value: number, maxNumber: number, minNumber: number): number {
  // To match numbers with three or more decimal places
  const patternThreeDecimal = /^\d+(\.\d{3,})$/;

  if (value > maxNumber) {
    value = maxNumber;
  } else if (value < minNumber) {
    value = minNumber;
  } else if (patternThreeDecimal.test(value.toString())) {
    value = parseFloat(value.toFixed(2));
  }

  return value;
}
