import { formatLocaleDate } from '@globals';
import { CodeableConcept, Consent, Extension, Reference } from '@hl7fhir';
import {
  CodeableConceptPipe,
  CodingsPipe,
  IdentifierViewModel,
  PeriodTypePipe,
  getChoiceOfType,
} from '@hl7fhir/data-types';
import { ExtensionPipe, ExtensionsPipe } from '@hl7fhir/extensibility';
import { getReference, getReferences } from '@hl7fhir/foundation';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { DomainResourceViewModel } from '@hl7fhir/viewmodels';
import {
  BehandelingCodelijst,
  BehandelingToegestaanCodelijst,
  GeverifieerdBijCodelijst,
  WilsverklaringTypeCodelijst,
} from '@hl7nl-fhir/value-sets';
import { SelectsPipe } from '@shared';
import * as r3 from 'fhir/r3';
import * as r4 from 'fhir/r4';
import * as r4b from 'fhir/r4b';
import * as r5 from 'fhir/r5';
import { ConsentActorViewModel } from './consent-actor.viewmodel';
import { ConsentDataViewModel } from './consent-data.viewmodel';
import { ConsentExceptViewModel } from './consent-except.viewmodel';
import { ConsentPolicyViewModel } from './consent-policy.viewmodel';

export class ConsentViewModel extends DomainResourceViewModel<Consent> {
  get status(): string | undefined {
    return this.resource?.status;
  }

  get category(): string | undefined {
    return (
      this.resource?.category &&
      new CodeableConceptPipe().transform(this.resource.category, [WilsverklaringTypeCodelijst])
    );
  }

  get patient(): string | undefined {
    const resourceR = this.resource as r3.Consent | r4.Consent | r4b.Consent | undefined;
    return resourceR?.patient && getReference(resourceR.patient);
  }

  get period(): string | undefined {
    const resourceR = this.resource as r3.Consent | r5.Consent | undefined;
    return resourceR?.period && new PeriodTypePipe().transform(resourceR.period);
  }

  get dateTime(): string | undefined {
    const resourceR = this.resource as r3.Consent | r4.Consent | r4b.Consent | undefined;
    return resourceR?.dateTime && formatLocaleDate(resourceR.dateTime, 'long');
  }

  get sortDate(): string | undefined {
    const resourceR = this.resource as r3.Consent | r4.Consent | r4b.Consent | undefined;
    return resourceR?.dateTime;
  }

  get consentingParty(): string | undefined {
    const resourceR3 = this.resource as r3.Consent | undefined;
    return resourceR3?.consentingParty && getReferences(resourceR3.consentingParty);
  }

  get action(): string | undefined {
    const resourceR3 = this.resource as r3.Consent | undefined;
    return resourceR3?.action && new CodeableConceptPipe().transform(resourceR3.action);
  }

  get organization(): string | undefined {
    const resourceR = this.resource as r3.Consent | r4.Consent | r4b.Consent | undefined;
    return resourceR?.organization && getReferences(resourceR.organization);
  }

  get source(): string | undefined {
    const resourceR = this.resource as r3.Consent | r4.Consent | r4b.Consent | undefined;
    const resourceR3 = this.resource as r3.Consent | undefined;
    return getChoiceOfType({
      attachment: resourceR?.sourceAttachment,
      reference: resourceR?.sourceReference,
      identifier: resourceR3?.sourceIdentifier,
    });
  }

  get policyRule(): string | undefined {
    const resourceR = this.resource as r3.Consent | undefined;
    return resourceR?.policyRule;
  }

  get securityLabel(): string | undefined {
    const resourceR = this.resource as r3.Consent | undefined;
    return resourceR?.securityLabel && new CodingsPipe().transform(resourceR.securityLabel);
  }

  get purpose(): string | undefined {
    const resourceR = this.resource as r3.Consent | undefined;
    return resourceR?.purpose && new CodingsPipe().transform(resourceR.purpose);
  }

  get dataPeriod(): string | undefined {
    const resourceR = this.resource as r3.Consent | undefined;
    return resourceR?.dataPeriod && new PeriodTypePipe().transform(resourceR.dataPeriod);
  }

  get verifiedWith(): string | undefined {
    const verificationExtension: Extension | undefined = new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.TREATMENT_DIRECTIVE.verification
    );
    const verifiedWithExtensions: Extension[] | undefined = new ExtensionsPipe().transform(
      verificationExtension?.extension,
      'VerifiedWith'
    );
    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectsPipe().transform(
      verifiedWithExtensions,
      'valueCodeableConcept'
    );
    return (
      valueCodeableConcepts && new CodeableConceptPipe().transform(valueCodeableConcepts, [GeverifieerdBijCodelijst])
    );
  }

  get treatment(): string | undefined {
    const extensions: Extension[] | undefined = new ExtensionsPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.TREATMENT_DIRECTIVE.treatment
    );
    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectsPipe().transform(
      extensions,
      'valueCodeableConcept'
    );
    return valueCodeableConcepts && new CodeableConceptPipe().transform(valueCodeableConcepts, [BehandelingCodelijst]);
  }

  get treatmentPermitted(): string | undefined {
    const extensions: Extension[] | undefined = new ExtensionsPipe().transform(
      this.resource?.modifierExtension,
      StructureDefinition.Nictiz.TREATMENT_DIRECTIVE.treatmentPermitted
    );
    const valueCodeableConcepts: CodeableConcept[] | undefined = new SelectsPipe().transform(
      extensions,
      'valueCodeableConcept'
    );
    return (
      valueCodeableConcepts &&
      new CodeableConceptPipe().transform(valueCodeableConcepts, [BehandelingToegestaanCodelijst])
    );
  }

  get disorder(): string | undefined {
    const extensions: Extension[] | undefined = new ExtensionsPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.ADVANCE_DIRECTIVE.disorder
    );
    const valueReferences: Reference[] | undefined = new SelectsPipe().transform(extensions, 'valueReference');
    return valueReferences && getReferences(valueReferences);
  }

  get comment(): string | undefined {
    const extension: Extension | undefined = new ExtensionPipe().transform(
      this.resource?.extension,
      StructureDefinition.Nictiz.ADVANCE_DIRECTIVE.comment
    );
    return extension?.valueString;
  }

  get identifier(): IdentifierViewModel[] | IdentifierViewModel | undefined {
    if (Array.isArray(this.resource?.identifier)) {
      return this.resource?.identifier.map((item) => new IdentifierViewModel(item, this.fhirVersion));
    }
    return this.resource?.identifier && new IdentifierViewModel(this.resource.identifier, this.fhirVersion);
  }

  get actor(): ConsentActorViewModel[] | undefined {
    const resourceR = this.resource as r3.Consent | undefined;
    return resourceR?.actor && resourceR.actor.map((item) => new ConsentActorViewModel(item, this.fhirVersion));
  }

  get policy(): ConsentPolicyViewModel[] | undefined {
    const resourceR = this.resource as r3.Consent | r4.Consent | r4b.Consent | undefined;
    return resourceR?.policy && resourceR.policy.map((item) => new ConsentPolicyViewModel(item, this.fhirVersion));
  }

  get data(): ConsentDataViewModel[] | undefined {
    const resourceR = this.resource as r3.Consent | undefined;
    return resourceR?.data && resourceR.data.map((item) => new ConsentDataViewModel(item, this.fhirVersion));
  }

  get except(): ConsentExceptViewModel[] | undefined {
    const resourceR = this.resource as r3.Consent | undefined;
    return resourceR?.except && resourceR.except.map((item) => new ConsentExceptViewModel(item, this.fhirVersion));
  }
}
