import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as features from '../../features';
import { ObservationEffects } from './observation.effects';
import { reducer } from './observation.reducer';

@NgModule({
  imports: [StoreModule.forFeature(features.FHIR.observations, reducer), EffectsModule.forFeature(ObservationEffects)],
})
export class ObservationStoreModule {}
