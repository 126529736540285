import { Coding, Observation } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { ObservationViewModel } from '@hl7fhir/resource-types';
import { StructureDefinition } from '@hl7fhir/structure-definitions';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '@store/router';
import * as features from '../../features';
import * as fromObservation from './observation.reducer';

export const selectObservationState = createFeatureSelector<fromObservation.State>(features.FHIR.observations);

export const selectObservationEntities = createSelector(
  selectObservationState,
  fromObservation.selectObservationEntities
);

export const selectAllObservations = createSelector(selectObservationState, fromObservation.selectAllObservations);

export const selectObservationById = createSelector(
  selectRouteParams,
  selectObservationEntities,
  ({ id }, observations: Dictionary<Observation>) =>
    id && (observations[id] ?? observations[id.toLowerCase()] ?? observations[id.toUpperCase()])
);

export const selectObservationsByCodings = (props: Coding[]) =>
  createSelector(selectAllObservations, (observations: Observation[]) => {
    const filteredObservations = observations.filter((observation: Observation) => {
      return observation.code.coding?.some((coding: Coding) =>
        props.some((prop) => coding.system === prop.system && coding.code === prop.code)
      );
    });

    return filteredObservations;
  });

export const selectMedMijLaboratoryResults = createSelector(selectAllObservations, (observations: Observation[]) =>
  observations.filter((observation) =>
    observation.meta?.profile?.some((profile) =>
      [
        StructureDefinition.Nictiz.OBSERVATION.gpLaboratoryResult,
        StructureDefinition.Nictiz.OBSERVATION.hospitalLaboratoryResultObservation,
        StructureDefinition.Nictiz.OBSERVATION.hospitalLaboratoryResultSpecimen,
      ].includes(profile)
    )
  )
);

export const selectFunctionalOrMentalStatusResults = createSelector(
  selectAllObservations,
  (observations: Observation[]) =>
    observations.filter((observation) =>
      observation?.category?.some((category) =>
        category?.coding?.some(
          (coding) =>
            coding && coding.system === CodeSystems.SNOMED && ['118228005', '384821006'].includes(coding.code ?? '')
        )
      )
    )
);

export const selectLivingSituationResults = createSelector(selectAllObservations, (observations: Observation[]) =>
  observations.filter((observation) =>
    observation?.code?.coding?.some(
      (coding) => coding && coding.system === CodeSystems.SNOMED && ['365508006'].includes(coding.code ?? '')
    )
  )
);

export const selectDrugUseResults = createSelector(selectAllObservations, (observations: Observation[]) =>
  observations.filter((observation) =>
    observation?.code?.coding?.some(
      (coding) => coding && coding.system === CodeSystems.SNOMED && ['228366006'].includes(coding.code ?? '')
    )
  )
);

export const selectTobaccoUseResults = createSelector(selectAllObservations, (observations: Observation[]) =>
  observations.filter((observation) =>
    observation?.code?.coding?.some(
      (coding) => coding && coding.system === CodeSystems.SNOMED && ['365980008'].includes(coding.code ?? '')
    )
  )
);

export const selectAlcoholUseResults = createSelector(selectAllObservations, (observations: Observation[]) =>
  observations.filter((observation) =>
    observation?.code?.coding?.some(
      (coding) => coding && coding.system === CodeSystems.SNOMED && ['228273003'].includes(coding.code ?? '')
    )
  )
);

export const selectAllObservationViewModels = createSelector(selectAllObservations, (dataSource: Observation[]) =>
  dataSource.map((item) => new ObservationViewModel(item))
);

export const selectAllAlcoholUseViewModels = createSelector(selectAlcoholUseResults, (dataSource: Observation[]) =>
  dataSource.map((item) => new ObservationViewModel(item))
);

export const selectAllFunctionalOrMentalStatusViewModels = createSelector(
  selectFunctionalOrMentalStatusResults,
  (dataSource: Observation[]) => dataSource.map((item) => new ObservationViewModel(item))
);

export const selectAllDrugUseViewModels = createSelector(selectDrugUseResults, (dataSource: Observation[]) =>
  dataSource.map((item) => new ObservationViewModel(item))
);

export const selectAllLivingSituationViewModels = createSelector(
  selectLivingSituationResults,
  (dataSource: Observation[]) => dataSource.map((item) => new ObservationViewModel(item))
);

export const selectAllMedMijLaboratoryViewModels = createSelector(
  selectMedMijLaboratoryResults,
  (dataSource: Observation[]) => dataSource.map((item) => new ObservationViewModel(item))
);

export const selectAllTobaccoUseViewModels = createSelector(selectTobaccoUseResults, (dataSource: Observation[]) =>
  dataSource.map((item) => new ObservationViewModel(item))
);

export const selectObservationViewModelById = createSelector(
  selectObservationById,
  (entity: Observation | undefined) => entity && new ObservationViewModel(entity)
);
