import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, mapToCanActivate } from '@angular/router';
import { DeepLinkLoginGuard } from '@authentication';
import {
  BlankComponent,
  CallbackComponent,
  DefaultComponent,
  LoginComponent,
  MainComponent,
  PageNotFoundComponent,
  ReturnComponent,
  TopMenuComponent,
} from '@core';
import { environment } from '@environments/environment';
import { addIf } from '@globals';
import { isAuthenticated } from './authentication/is-authenticated';
import { GettingStartedConsentContainerComponent } from './pages/onboard-or-log-in/getting-started-consent.container';
import { OnboardOrLogInContainerComponent } from './pages/onboard-or-log-in/onboard-or-log-in.container';

// The 'routes' variable should ideally be of type 'Routes'.
// However, due to an issue with compodoc (https://github.com/compodoc/compodoc/issues/525),
// we are using 'any' as a workaround.
export const routes: any = [
  { path: ':locale/callback', component: CallbackComponent },
  { path: ':locale/blank', component: BlankComponent },
  {
    path: ':locale',
    component: DefaultComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: MainComponent,
        loadChildren: () => import('./pages/home-page/home-page-routing.module').then((m) => m.HomePageRoutingModule),
        canActivate: [isAuthenticated],
      },
      { path: 'return', component: ReturnComponent },
      {
        path: '',
        component: TopMenuComponent,
        loadChildren: () => import('./pages/fhir/fhir.module').then((m) => m.FhirModule),
      },
      {
        path: 'my-details',
        component: MainComponent,
        loadChildren: () => import('./pages/my-details/my-details.module').then((m) => m.MyDetailsModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'my-health-record',
        component: MainComponent,
        loadChildren: () =>
          import('./pages/my-health-record/my-health-record.module').then((m) => m.MyHealthRecordModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'linked-sources',
        component: MainComponent,
        loadChildren: () =>
          import('./pages/linked-sources/linked-sources-routing.module').then((m) => m.LinkedSourcesRoutingModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'portability-report',
        component: TopMenuComponent,
        loadChildren: () =>
          import('./pages/portability-report/portability-report-routing.module').then(
            (m) => m.PortabilityReportRoutingModule,
          ),
        canActivate: [isAuthenticated],
      },
      ...addIf(environment.features.measurements.share, {
        path: 'share',
        component: TopMenuComponent,
        loadChildren: () =>
          import('./pages/share-measurements/share-measurements-routing.module').then(
            (m) => m.ShareMeasurementsRoutingModule,
          ),
        canActivate: [isAuthenticated],
      }),
      {
        path: 'notifications',
        component: MainComponent,
        loadChildren: () =>
          import('./pages/notifications/notifications-routing.module').then((m) => m.NotificationsRoutingModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'ips',
        component: MainComponent,
        loadChildren: () => import('./pages/ips/ips-routing.module').then((m) => m.IpsRoutingModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'opt',
        component: MainComponent,
        loadChildren: () => import('./pages/opt/opt-routing.module').then((m) => m.OptRoutingModule),
        canActivate: [isAuthenticated],
      },
      {
        path: 'settings',
        component: MainComponent,
        loadChildren: () => import('./pages/settings/settings-routing.module').then((m) => m.SettingsRoutingModule),
        canActivate: [isAuthenticated],
      },
      {
        path: '',
        component: TopMenuComponent,
        children: [
          { path: 'onboard-or-log-in', component: OnboardOrLogInContainerComponent },
          { path: 'consent', component: GettingStartedConsentContainerComponent },
        ],
      },
    ],
  },
  {
    path: ':locale/login',
    component: LoginComponent,
    canActivate: mapToCanActivate([DeepLinkLoginGuard]),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules, // Preload, because otherwise the navigation cannot be completed if all files are loaded
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
